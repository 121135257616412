import { Drawer, Input, DatePicker, Select, Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import Loading from '../common/Loading';
import '../../../assets/sass/dsk/promotion.sass';
import { ArrowLeftOutlined, CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';
import InputValidation from '../common/InputValidation';
import { onUpdatePromotion, onAddPromotion, onGenerateRuleDesc } from '../../../actions/promotionAction';
import dayjs from 'dayjs';
import { isNotNullOrUndefinedString } from '../../../helpers/util';
import { useDispatch } from 'react-redux';
import { getOutlets } from '../../../actions/outletAction';
import { getAdditionalCharges } from '../../../actions/commonAction';
import { getL10N } from '../../../helpers/middleware';
import { getProducts } from '../../../actions/productActions';
import DeleteSvg from '../../../assets/images/settings/trash.svg';
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
import EmptyList from '../../../assets/images/empty-list.svg';
import EditSvg from '../../../assets/images/edit-icon.svg';
import { RuleBuilder } from "../common/ruleBuilder";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const Save = (props: any) => {
    const dispatch = useDispatch();
    // const sDate = dayjs();
    // const eDate = dayjs();
    // const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [selectedDate, setSelectedDate]: any = useState(null);
    const [code, setCode] = useState(null);
    const [promotionType, setPromotionType] = useState('waiveOffCharge');
    const [attachPromotion, setAttachPromotion] = useState('store');
    const [percentage, setPercentage] = useState(null);
    const [maxDiscountAmount, setMaxDiscountAmount] = useState(null);
    const [flatAmount, setFlatAmount] = useState(0);
    const [validFrom, setValidFrom] = useState(null);
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [validUpto, setValidUpto] = useState(null);
    const [outlets, setOutlets] = useState([]);
    const [productList, setProductList] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductList, setSelectedProductList] = useState([]);
    const [productQty, setProductQty] = useState(1);
    const [searchProduct, setSearchProduct] = useState(null);
    const [selectedRuleIndex, setSelectedRuleIndex] = useState(null);
    const [products, setProducts] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [additionalCharges, setAdditionalCharges] = useState([]);
    const [hidden, setHidden] = useState(false);
    // const [addons, setAddons] = useState([]);
    const [isRuleBuilder, setRuleBuilder] = useState(false);
    const [selectedAdditionalCharges, setSelectedAdditionalCharges] = useState(null);
    const [rule, setRule] = useState(null);
    // const [rules, setRules] = useState([]);
    const [rules, setRules] = useState([]);
    let errorInfo = {
        title: {
            message: "",
            type: ""
        },
        selectOutlet: {
            message: "",
            type: ""
        },
        description: {
            message: "",
            type: ""
        },
        code: {
            message: "",
            type: ""
        },
        percentage: {
            message: "",
            type: ""
        },
        amount: {
            message: "",
            type: ""
        },
        selectedAdditionalCharges: {
            message: "",
            type: ""
        },
        maxDiscountAmount: {
            message: "",
            type: ""
        },
        available: {
            message: "",
            type: ""
        },
        validDate: {
            message: "",
            type: ""
        },
        minimumOrder: {
            message: "",
            type: ""
        },
        flatAmount: {
            message: "",
            type: ""
        },
        selectedProduct: {
            message: "",
            type: ""
        }
    };
    const [error, setError] = useState(errorInfo);
    const onSubmit = async () => {
        const { selectedPromotion }: any = props;
        let isError = false;
        if (!isNotNullOrUndefinedString(title)) {
            errorInfo.title.message = "Enter the promotion title";
            errorInfo.title.type = "error";
            isError = true;
        } else {
            errorInfo.title.message = "";
            errorInfo.title.type = "";
        }
        if (!isNotNullOrUndefinedString(description)) {
            errorInfo.description.message = "Enter the promotion description";
            errorInfo.description.type = "error";
            isError = true;
        } else {
            errorInfo.description.message = "";
            errorInfo.description.type = "";
        }
        if (!isNotNullOrUndefinedString(code)) {
            errorInfo.code.message = "Enter the promotion code";
            errorInfo.code.type = "error";
            isError = true;
        } else {
            errorInfo.code.message = "";
            errorInfo.code.type = "";
        }
        // if (!minOrderValue) {
        //     errorInfo.minimumOrder.message = "Enter the promotion minimum order value";
        //     errorInfo.minimumOrder.type = "error";
        //     isError = true;
        // } else {
        //     errorInfo.minimumOrder.message = "";
        //     errorInfo.minimumOrder.type = "";
        // }
        if (promotionType === "waiveOffCharge") {
            if (!selectedAdditionalCharges) {
                errorInfo.selectedAdditionalCharges.message = "Select the additional charges";
                errorInfo.selectedAdditionalCharges.type = "error";
                isError = true;
            } else {
                errorInfo.selectedAdditionalCharges.message = "";
                errorInfo.selectedAdditionalCharges.type = "";
            }
        }
        if (promotionType === "freeProducts") {
            if (selectedProductList.length === 0) {
                errorInfo.selectedProduct.message = "Select the products";
                errorInfo.selectedProduct.type = "error";
                isError = true;
            } else {
                errorInfo.selectedProduct.message = "";
                errorInfo.selectedProduct.type = "";
            }
        }
        if (promotionType === "flat") {
            if (!flatAmount) {
                errorInfo.flatAmount.message = "Enter the promotion flat amount";
                errorInfo.flatAmount.type = "error";
                isError = true;
            } else {
                errorInfo.flatAmount.message = "";
                errorInfo.flatAmount.type = "";
            }
        }
        if (attachPromotion === "outlets" && selectedOutlets.length === 0) {
            errorInfo.selectOutlet.message = "Select the outlets";
            errorInfo.selectOutlet.type = "error";
            isError = true;
        } else {
            errorInfo.selectOutlet.message = "";
            errorInfo.selectOutlet.type = "";
        }
        if (promotionType === "percentage") {
            if (!percentage) {
                errorInfo.percentage.message = "Enter the promotion percentage";
                errorInfo.percentage.type = "error";
                isError = true;
            } else {
                errorInfo.percentage.message = "";
                errorInfo.percentage.type = "";
            }
            // if (!maxDiscountAmount) {
            //     errorInfo.maxDiscountAmount.message = "Enter the promotion max discount amount";
            //     errorInfo.maxDiscountAmount.type = "error";
            //     isError = true;
            // } else {
            //     errorInfo.maxDiscountAmount.message = "";
            //     errorInfo.maxDiscountAmount.type = "";
            // }
        } else {
            errorInfo.percentage.message = "";
            errorInfo.percentage.type = "";
            errorInfo.maxDiscountAmount.message = "";
            errorInfo.maxDiscountAmount.type = "";
        }
        // if (promotionType === "amount") {
        //     if (!amount) {
        //         errorInfo.amount.message = "Enter the promotion amount";
        //         errorInfo.amount.type = "error";
        //         isError = true;
        //     } else {
        //         errorInfo.amount.message = "";
        //         errorInfo.amount.type = "";
        //     }
        // } else {
        //     errorInfo.amount.message = "";
        //     errorInfo.amount.type = "";
        // }
        // if (!available) {
        //     errorInfo.available.message = "Enter the promotion available";
        //     errorInfo.available.type = "error";
        //     isError = true;
        // } else {
        //     errorInfo.available.message = "";
        //     errorInfo.available.type = "";
        // }
        if (!isNotNullOrUndefinedString(validFrom)) {
            errorInfo.validDate.message = "Enter the promotion valid from";
            errorInfo.validDate.type = "error";
            isError = true;
        } else {
            errorInfo.validDate.message = "";
            errorInfo.validDate.type = "";
        }
        if (!isNotNullOrUndefinedString(validUpto)) {
            errorInfo.validDate.message = "Enter the promotion valid upto";
            errorInfo.validDate.type = "error";
            isError = true;
        } else {
            errorInfo.validDate.message = "";
            errorInfo.validDate.type = "";
        }
        if (!isError) {
            setIsLoading(true);
            const payload = {
                title,
                description,
                code,
                hidden,
                // available,
                validFrom,
                rules: JSON.parse(JSON.stringify(rules)),
                appliesTo: attachPromotion,
                type: promotionType,
                validUpto
            };
            if (attachPromotion === "outlets") {
                payload["outletIds"] = selectedOutlets;
            }
            if (promotionType === "freeProducts") {
                payload["products"] = selectedProductList;
            } else if (promotionType === "waiveOffCharge") {
                payload["chargeId"] = selectedAdditionalCharges;
            } else if (promotionType === "percentage") {
                payload["percentage"] = percentage;
                if (maxDiscountAmount && maxDiscountAmount > 0) {
                    payload["maxDiscount"] = maxDiscountAmount;
                }
            } else if (promotionType === "flat") {
                payload["flatDiscount"] = flatAmount;
            }
            if (payload.rules.length > 0) {
                payload.rules.forEach(r => {
                    delete r.description;
                });
            }
            let resp = null;
            if (selectedPromotion && selectedPromotion.id) {
                resp = await onUpdatePromotion(selectedPromotion.id, payload)(dispatch);
            } else {
                resp = await onAddPromotion(payload)(dispatch);
            }
            setIsLoading(false);
            if (resp) {
                onClose();
            }
        }
        setError(errorInfo);
    }
    useEffect(() => {
        setIsEdit(false);
        const { selectedPromotion }: any = props;
        let dates = [];
        if (selectedPromotion) {
            if (selectedPromotion.title) {
                setTitle(selectedPromotion.title);
            }
            if (selectedPromotion.description) {
                setDescription(selectedPromotion.description);
            }
            if (selectedPromotion.code) {
                setCode(selectedPromotion.code);
            }
            //waiveOffCharge freeProducts percentage flat
            if (selectedPromotion.type) {
                setPromotionType(selectedPromotion.type);
            }
            if (selectedPromotion.appliesTo) {
                setAttachPromotion(selectedPromotion.appliesTo);
                if (selectedPromotion.appliesTo === "outlets" && selectedPromotion.outletIds) {
                    setSelectedOutlets(selectedPromotion.outletIds);
                }
            }
            if (selectedPromotion.type === "waiveOffCharge" && selectedPromotion.chargeId) {
                setSelectedAdditionalCharges(selectedPromotion.chargeId);
            }
            if (selectedPromotion.type === "freeProducts" && selectedPromotion.products) {
                setSelectedProductList(selectedPromotion.products);
            }
            if (selectedPromotion.type === "percentage") {
                if (selectedPromotion.percentage) {
                    setPercentage(selectedPromotion.percentage);
                }
                if (selectedPromotion.maxDiscount) {
                    setMaxDiscountAmount(selectedPromotion.maxDiscount);
                }
            }
            if (selectedPromotion.type === "flat") {
                if (selectedPromotion.flatDiscount && selectedPromotion.flatDiscount) {
                    setFlatAmount(selectedPromotion.flatDiscount);
                }
            }
            if (selectedPromotion.rules) {
                setRules(selectedPromotion.rules);
            }
            if (selectedPromotion.validFrom) {
                setValidFrom(selectedPromotion.validFrom);
                dates.push(dayjs(selectedPromotion.validFrom));
            }
            if (selectedPromotion.validUpto) {
                setValidUpto(selectedPromotion.validUpto);
                dates.push(dayjs(selectedPromotion.validUpto));
            }
            if (selectedPromotion.hidden) {
                setHidden(selectedPromotion.hidden);
            }
            if (dates.length > 0) {
                setSelectedDate(dates);
            }
        } else {
            loadInitialData();
        }
        if (!selectedPromotion || !selectedPromotion.id) {
            setIsEdit(true);
        }
        //eslint-disable-next-line
    }, [props.selectedPromotion, props.isVisible]);

    const loadInitialData = () => {
        setError(errorInfo);
        setTitle(null);
        setDescription(null);
        setCode(null);
        setRule(null);
        setRules([]);
        setPercentage(null);
        setMaxDiscountAmount(null);
        setPromotionType('waiveOffCharge');
        setSelectedDate(null);
        setValidFrom(null);
        setValidUpto(null);
        setSelectedOutlets([]);
        setSelectedProductId(null);
        setSelectedProductList([]);
        setProductQty(1);
        setSearchProduct(null);
        setHidden(false);
        // setProducts([]);
        // setProductList([]);
        // setOutlets([]);
        // setAdditionalCharges([]);
        setSelectedAdditionalCharges(null);
    }

    const onChangeDate = (dayjsDate, dateString) => {
        if (dateString.length > 1) {
            setSelectedDate([dayjs(dayjsDate[0]).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0), dayjs(dayjsDate[1]).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)]);
            setValidUpto(dayjs(dayjsDate[1]).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString());
            setValidFrom(dayjs(dayjsDate[0]).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString());
        }
    }
    const handlePercentage = event => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setPercentage(parseInt(value))
    };
    const loadOutlets = async () => {
        const respOut = await getOutlets()(dispatch);
        if (respOut) {
            setOutlets(respOut)
        }
    }
    const loadAdditionalCharges = async () => {
        const resp = await getAdditionalCharges()(dispatch);
        if (resp?.additionalCharges) {
            setAdditionalCharges(resp.additionalCharges);
        }
    }

    const loadProducts = async () => {
        let resp = await getProducts()(dispatch);
        if (resp) {
            setProducts(resp);
            setProductList(resp);
        }
    }
    const onSearchProduct = () => {
        let newList = [];
        for (let product of products) {
            const title = getL10N(product, "title", null).i10n;
            if (
                searchProduct === null ||
                searchProduct.length === 0 ||
                title.toLowerCase().indexOf(searchProduct.toLowerCase()) > -1
            ) {
                newList.push(product);
            }
        }
        setProductList(newList);

    };
    const onSelectedProduct = () => {
        setSelectedProductList([...selectedProductList, { id: selectedProductId, quantity: productQty }]);
        setSelectedProductId(null);
        setProductQty(1);
    }
    useEffect(() => {
        onSearchProduct();
        //eslint-disable-next-line
    }, [searchProduct]);
    useEffect(() => {
        if (props.isVisible) {
            if (promotionType === "waiveOffCharge") {
                loadAdditionalCharges();
            } else if (promotionType === "freeProducts") {
                loadProducts();
            }
        }
        //eslint-disable-next-line
    }, [promotionType]);
    useEffect(() => {
        if (props.isVisible) {
            loadOutlets();
            loadProducts();
            loadAdditionalCharges();
        }
        //eslint-disable-next-line
    }, [props.isVisible]);
    const onRuleChanges = async (rule, status) => {
        setRuleBuilder(false);
        setRule(null);
        if (rule.conditions) {
            setIsLoading(true);
            const resp = await onGenerateRuleDesc({ conditions: rule.conditions })(dispatch);
            setIsLoading(false);
            if (resp && resp.description) {
                rule.description = resp.description;
            } else {
                rule.description = "No description found";
            }
        }
        if (selectedRuleIndex !== null) {
            let newRules = [...rules];
            newRules[selectedRuleIndex] = rule;
            setRules(newRules);
            setSelectedRuleIndex(null);
        } else {
            setRules([...rules, rule]);
        }
    }
    useEffect(() => {
        rules.forEach((rule, index) => {
            if (index < rules.length && !rule.combinator) {
                rule.combinator = 'and';
            } else if (!rule.combinator) {
                delete rule.combinator;
            }
        });
    }, [rules]);
    const onRemoveRule = (index) => {
        let newRules = [...rules];
        newRules.splice(index, 1);
        setRules(newRules);
    }
    const onClose = () => {
        loadInitialData();
        setIsEdit(false);
        props.onClose();
    }

    useEffect(() => {
        if (rule) {
            setRuleBuilder(true);
        }
        //eslint-disable-next-line
    }, [rule]);
    // useEffect(() => {
    //     if (selectedProductId) {
    //         loadAddons();
    //     }
    //     //eslint-disable-next-line
    // }, [selectedProductId]);
    // const loadAddons = async () => {
    //     setIsLoading(true);
    //     const resp = await getAddOnGroups(selectedProductId)(dispatch);
    //     setAddons(resp);
    //     setIsLoading(false);
    // }
    return <Drawer
        width={"100%"}
        closable={false}
        onClose={() => { setError(errorInfo); onClose(); }}
        className="promotion-view"
        open={props.isVisible}
        footer={
            <div className='footer'>
                <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                {isEdit && <div className="sp_btn" onClick={onSubmit} style={{ width: 164, fontSize: 14 }}>{props.selectedPromotion ? "Update Promotion" : "Create Promotion"}</div>}
            </div>
        }>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
            <div className="title">{props.selectedPromotion ? "Update " : "Add "}Promotion Details</div>
        </div>
        <div className="content" >
            <div className='section' style={{ width: '340', height: "98%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div className="group" style={{ marginBottom: 16 }}>
                        <div>
                            <div className="label" >Title *</div>
                        </div>
                        <div>
                            <InputValidation type={error.title.type} message={error.title.message}>
                                <Input className="value" onChange={(e) => setTitle(e.target.value)} disabled={!isEdit} value={title} />
                            </InputValidation>
                        </div>
                    </div>
                    <div className="group" style={{ marginBottom: 16 }}>
                        <div>
                            <div className="label" >Code *</div>
                        </div>
                        <div>
                            <InputValidation type={error.code.type} message={error.code.message}>
                                <Input className="value" onChange={(e) => setCode(e.target.value)} disabled={!isEdit} value={code} />
                            </InputValidation>
                        </div>
                    </div>
                    <div className="group" style={{ marginBottom: 16 }}>
                        <div>
                            <div className="label" >Description *</div>
                        </div>
                        <div>
                            <InputValidation type={error.description.type} message={error.description.message}>
                                <TextArea className="value" onChange={(e) => setDescription(e.target.value)} disabled={!isEdit} value={description} />
                            </InputValidation>
                        </div>
                    </div>
                    <div className="group" style={{ marginBottom: 16 }}>
                        <div>
                            <div className="label" >Valid *</div>
                        </div>
                        <div>
                            <InputValidation type={error.validDate.type} message={error.validDate.message}>
                                <div className="date-range">
                                    {/* disabledDate={disabledDate} */}
                                    <RangePicker disabled={!isEdit} value={selectedDate} onChange={(dates, dateStrings) => onChangeDate(dates, dateStrings)} />
                                </div>
                            </InputValidation>
                        </div>
                    </div>

                    <div className="group">
                        <div>
                            <div className="label" >Attach Promotion *</div>
                        </div>
                        <div>
                            <Select disabled={!isEdit} value={attachPromotion} onChange={(val) => setAttachPromotion(val)} placeholder={"Attach your promotion"}>
                                <Option key="store" value="store">Store</Option>
                                <Option key="outlets" value="outlets">Outlet</Option>
                            </Select>
                        </div>
                    </div>

                    {attachPromotion === "outlets" && <div className="group" style={{ marginTop: 16 }}>
                        <div>
                            <div className="label" >Outlets *</div>
                        </div>
                        <div>
                            <InputValidation type={error.selectOutlet.type} message={error.selectOutlet.message}>
                                <Select disabled={!isEdit} style={{ height: '150px !important' }} mode="multiple" value={selectedOutlets} onChange={(val) => setSelectedOutlets(val)}>
                                    {outlets && outlets.map((item) => {
                                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                                    })}
                                </Select>
                            </InputValidation>
                        </div>
                    </div>}
                </div>
                <div style={{
                    borderTop: '1px solid rgba(217, 217, 217, 0.40)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: 8
                }}>
                    <div className='hide-from-list'>Hide from list</div>
                    <div>
                        <Switch
                            className="toggle-opt"
                            disabled={!isEdit}
                            onClick={(val) => {
                                setHidden(val);
                            }}
                            checked={hidden}
                        />
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', marginLeft: 12, display: "flex", flexDirection: "column", alignContent: "stretch" }}>
                <div className='section' style={{ width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="group" style={{ width: 310, marginRight: 15 }}>
                            <div>
                                <div className="label" >Promotion Type *</div>
                            </div>
                            <div>
                                <Select disabled={!isEdit} value={promotionType} onChange={(val) => setPromotionType(val)} placeholder={"Set your business hours"}>
                                    <Option key="waiveOffCharge" value="waiveOffCharge">Waive Off Charges</Option>
                                    {/* <Option key="freeProducts" value="freeProducts">Free Products</Option> */}
                                    <Option key="percentage" value="percentage">Percentage</Option>
                                    <Option key="flat" value="flat">Flat</Option>
                                </Select>
                            </div>
                        </div>
                        {promotionType === "waiveOffCharge" && <div className="group" style={{ width: 310 }}>
                            <div>
                                <div className="label" >Additional Charges *</div>
                            </div>
                            <div>
                                <InputValidation
                                    type={error.selectedAdditionalCharges.type}
                                    message={error.selectedAdditionalCharges.message}
                                >
                                    <Select disabled={!isEdit} value={selectedAdditionalCharges} onChange={(val) => setSelectedAdditionalCharges(val)} placeholder={"Additional Charges"}>
                                        {additionalCharges && additionalCharges.map((item) => {
                                            return <Option key={item.id} value={item.id}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{
                                                        width: 6,
                                                        height: 6,
                                                        borderRadius: 15,
                                                        marginRight: 10,
                                                        boxShadow: `${item.isActive ? '#21c082' : '#c7c7c7'} 0px 0px 6px`,
                                                        backgroundColor: item.isActive ? "#21C083" : "#c7c7c7"
                                                    }}></div>
                                                    <div>{item.label}</div>
                                                </div>
                                            </Option>
                                        })}
                                    </Select>
                                </InputValidation>
                            </div>
                        </div>}
                        {promotionType === "freeProducts" && <div className='free-products' >
                            <div className="group" style={{ width: 'auto' }}>
                                <div>
                                    <div className="label" >Products</div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <InputValidation
                                        type={error.selectedProduct.type}
                                        message={error.selectedProduct.message}
                                    >
                                        <div className='product-qty'>
                                            <Select
                                                style={{ width: 300 }}
                                                showSearch={selectedProductId ? false : true}
                                                suffixIcon={
                                                    selectedProductId ? (
                                                        <div>
                                                            <CloseCircleOutlined onClick={() => { setSelectedProductId(null); }} />
                                                        </div>
                                                    ) : (
                                                        <CaretDownOutlined />
                                                    )
                                                }
                                                disabled={!isEdit}
                                                className="add-product"
                                                placeholder="Select to add product"
                                                filterOption={false}
                                                // mode="tags" maxTagCount={1}
                                                value={selectedProductId}
                                                onSearch={(value: string) => {
                                                    setSearchProduct(value);
                                                }}
                                                onChange={(id: string) => {
                                                    setSelectedProductId(id);
                                                }}
                                            >
                                                {productList.map((p) => {
                                                    return (
                                                        <Option key={p.id} value={p.id}>
                                                            <div
                                                                style={{
                                                                    height: 40,
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "space-around",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        fontSize: 14,
                                                                        color: "#121212",
                                                                        fontWeight: 900,
                                                                        textTransform: "uppercase",
                                                                    }}
                                                                >
                                                                    {getL10N(p, "title", null).i10n}
                                                                </div>
                                                            </div>
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            <div className='qty'>
                                                Qty <Input className="value" type="text"
                                                    onChange={(e) => {
                                                        const { value }: any = e.target;
                                                        const reg = /^-?\d*(\.\d*)?$/;
                                                        if ((!isNaN(value) && reg.test(value)) || value === "" || value !== "-") {
                                                            setProductQty(parseInt(value || 1));
                                                        }
                                                    }} disabled={!isEdit} value={productQty} />
                                            </div>
                                        </div>
                                    </InputValidation>
                                    {selectedProductId && <div className="sp_btn" onClick={onSelectedProduct} style={{ width: 124, fontSize: 14, marginTop: 2, marginLeft: 10 }}>Add Product</div>}
                                </div>
                            </div>
                        </div>}
                        {promotionType === "percentage" && <div style={{ display: "flex" }}>
                            <div className="group" style={{ width: 310 }}>
                                <div>
                                    <div className="label" >Discount Percentage *</div>
                                </div>
                                <div>
                                    <InputValidation type={error.percentage.type} message={error.percentage.message}>
                                        <Input
                                            prefix={"%"}
                                            disabled={!isEdit}
                                            value={percentage}
                                            onChange={handlePercentage}
                                            type="number"
                                            min="1"
                                            max="100"
                                            className="value"
                                        />
                                    </InputValidation>
                                </div>
                            </div>
                            <div className="group" style={{ width: 310, marginLeft: 15 }}>
                                <div>
                                    <div className="label" >Max Discount Amount</div>
                                </div>
                                <div>
                                    {/* <InputValidation type={error.maxDiscountAmount.type} message={error.maxDiscountAmount.message}> */}
                                    <Input prefix={"₹"} className="value" type="number"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            // const reg = /^-?\d*(\.\d*)?$/;
                                            // if ((!isNaN(value) && reg.test(value)) || value === "" || value !== "-") {
                                            setMaxDiscountAmount(parseInt(value));
                                            // }
                                        }} disabled={!isEdit} value={maxDiscountAmount} />
                                    {/* </InputValidation> */}
                                </div>
                            </div>
                        </div>}
                        {promotionType === "flat" && <div style={{ display: "flex" }}>
                            <div className="group" style={{ width: 310 }}>
                                <div>
                                    <div className="label" >Amount *</div>
                                </div>
                                <div>
                                    <InputValidation type={error.flatAmount.type} message={error.flatAmount.message}>
                                        <Input prefix={"₹"} className="value" type="text"
                                            onChange={(e) => {
                                                const { value }: any = e.target;
                                                const reg = /^-?\d*(\.\d*)?$/;
                                                if ((!isNaN(value) && reg.test(value)) || value === "" || value !== "-") {
                                                    setFlatAmount(parseInt(value || 0));
                                                }
                                            }} disabled={!isEdit} value={flatAmount} />
                                    </InputValidation>
                                </div>
                            </div>
                        </div>}
                        {/* {promotionType === "minimumorder" && <div style={{ display: "flex" }}>
                            <div className="group" style={{ width: 310 }}>
                                <div>
                                    <div className="label" >Minimum Order Value *</div>
                                </div>
                                <div>
                                    <InputValidation type={error.minimumOrder.type} message={error.minimumOrder.message}>
                                        <Input prefix={"₹"} className="value" type="text"
                                            onChange={(e) => {
                                                const { value }: any = e.target;
                                                const reg = /^-?\d*(\.\d*)?$/;
                                                if ((!isNaN(value) && reg.test(value)) || value === "" || value !== "-") {
                                                    setMinOrderValue(parseInt(value || 0));
                                                }
                                            }} disabled={!isEdit} value={minOrderValue} />
                                    </InputValidation>
                                </div>
                            </div>
                        </div>} */}
                    </div>
                    {promotionType === "freeProducts" && selectedProductList.length > 0 && <div className='selected-prod-list'>
                        {selectedProductList.map((item) => {
                            const pro = products.find((p) => p.id === item.id);
                            return <div className="item" key={pro.id}>
                                <img src={pro.image || NoImageAvailableIcn} alt={pro.title} />
                                <div style={{ width: 200 }}>
                                    <div className="title">{pro.title}</div>
                                    <div className="qty">
                                        <span className='subtitle'>Qty:</span>
                                        <span className='qty'>{item.quantity}</span>
                                    </div>
                                </div>
                                <img src={DeleteSvg} alt="delete" className='delete' onClick={() => {
                                    const newList = selectedProductList.filter((p) => p.id !== item.id);
                                    setSelectedProductList(newList);
                                }} />
                            </div>
                        })}
                    </div>}
                </div>
                <div className='section' style={{ margin: "15px 0px", flexGrow: 1 }}>
                    {rules.length > 0 ? <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                            <div style={{
                                fontSize: 18,
                                fontWeight: 600
                            }}>Rules</div>
                            {isEdit && <div className='sp_btn' style={{ width: 120, fontSize: 14, marginTop: 10 }} onClick={() => { setRule(null); setRuleBuilder(true); setSelectedRuleIndex(null); }}>Add Rule</div>}
                        </div>
                        <div className='rules'>
                            {rules.map((item: any, index: number) => {
                                return <div key={`rule-${index}`}>
                                    <div className='rule'>{item.description}</div>
                                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <div>
                                            {item.combinator && rules.length - 1 !== index &&
                                                <Select className='op' disabled={!isEdit} value={item.combinator} onChange={(val) => {
                                                    let newRules = [...rules];
                                                    newRules[index].combinator = val;
                                                    setRules(newRules);
                                                }}>
                                                    <Option value="and">AND</Option>
                                                    <Option value="or">OR</Option>
                                                </Select>
                                            }
                                        </div>
                                        {isEdit && <div style={{ display: 'flex', alignItems: "center", color: "#3C3C3C", fontWeight: 400 }}>
                                            <div style={{ display: 'flex', alignItems: "center", cursor: 'pointer', marginRight: 16 }} onClick={() => { setSelectedRuleIndex(index); setRule(item); }} >
                                                <img src={EditSvg} alt='edit icon' style={{ marginRight: 4, height: 16 }} />
                                                Edit
                                            </div>
                                            <div style={{ display: 'flex', alignItems: "center", cursor: 'pointer' }} onClick={() => onRemoveRule(index)}>
                                                <img src={DeleteSvg} alt='delete icon' style={{ marginRight: 4, height: 16 }} />
                                                Remove
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : <div className='empty-list'>
                        <img src={EmptyList} alt="empty-list" />
                        <div style={{ color: "#797979", fontSize: 14, fontWeight: 500 }}>You have not added any rule yet.</div>
                        {isEdit && <div className='sp_btn' style={{ width: 120, fontSize: 14, marginTop: 10 }} onClick={() => setRuleBuilder(true)}>Add Rule</div>}
                    </div>}
                </div>
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
        <Modal
            closable={false}
            width={"75%"}
            className="rule-builder-modal"
            open={isRuleBuilder}
            footer={null}
        >
            <RuleBuilder
                onSave={onRuleChanges}
                key={'promotion-rule-builder'}
                products={products}
                rule={{ ...rule }}
                customConditions={[{
                    id: "product",
                    label: "Product"
                }, {
                    id: "customerOrderCount",
                    label: "Customer Order Count"
                }, {
                    id: "deviceType",
                    label: "Device Type"
                }, {
                    id: "minOrderValue",
                    label: "Minimum Order Value"
                }, {
                    id: "daysSinceLastOrder",
                    label: "Days Since Last Order"
                }]}
                onClose={() => { setRule(null); setRuleBuilder(false); }} />
        </Modal>
    </Drawer>
}

export default Save;