import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import WhatsappSvg from "../../../../../assets/images/settings/whatsapp.svg";
import WhatsappWhiteSvg from "../../../../../assets/images/whatsapp-white.svg";
import WhatsappBotSvg from "../../../../../assets/images/whatsapp-bot.svg";
import { updateChannelSettings, getChannelSettings } from "../../../../../actions/commonAction";
import { useDispatch } from "react-redux";
import apisUrl from "../../../../../apiConfig";
import PopupConfirm from "../../../common/PopupConfirm";
import Loading from "../../../common/Loading";
// import MarkdownViewer from "../../../common/MarkdownViewer";

const Whatsapp = () => {
    const dispatch = useDispatch();
    const WindowObj: any = window;
    const [enabled, setEnabled] = useState(null);
    const [wabaId, setWabaId] = useState(null);
    const [phoneNumberId, setPhoneNumberId] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fbData, setFBData] = useState(null);
    const [authCode, setAuthCode] = useState(null);

    const fetchData = async () => {
        const data = await getChannelSettings('whatsapp')(dispatch);
        if (data) {
            setEnabled(data.enabled);
            setWabaId(data.wabaId || null);
            setPhoneNumberId(data.phoneNumberId || null);
        } else {
            setEnabled(null);
            setWabaId(null);
            setPhoneNumberId(null);
        }
    }

    const fbAsyncInit = () => {
        WindowObj.FB.init({
            appId: '1009393129632820',
            xfbml: true,
            version: "v21.0",
            autoLogAppEvents: true,
        });
    };
    // Session logging message event listener
    const onHandleMessage = (event) => {
        if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
        try {
            const resp = JSON.parse(event.data);
            if (resp.type === 'WA_EMBEDDED_SIGNUP') {
                setFBData({
                    phoneNumberId: resp?.data?.phone_number_id,
                    wabaId: resp?.data?.waba_id
                });
            }
        } catch {
            console.error('message event: ', event.data); // remove after testing
            // your code goes here
        }
    }
    const onSave = async () => {
        if (enabled && authCode && fbData?.phoneNumberId && fbData?.wabaId) {
            setIsLoading(true);
            const resp = await updateChannelSettings('whatsapp', {
                authCode,
                phoneNumberId: fbData.phoneNumberId,
                wabaId: fbData.wabaId,
                enabled
            })(dispatch);
            setIsLoading(false);
            if (resp?.isUpdated) {
                addPaymentPopup();
                fetchData();
            }
        }
    }
    const addPaymentPopup = () => {
        const title = `Success`;
        setPopupConfirm({
            title,
            renderContent: () => {
                return <div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 500,
                        marginBottom: 24
                    }}>Connected to WhatsApp business account.</div>
                    <div style={{
                        padding: '10px 15px',
                        background: '#D9D9D92E',
                        borderRadius: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 9
                    }}>
                        <div style={{
                            fontSize: 13,
                            fontWeight: 600,
                            color: '#121212'
                        }}>Add Payment Method to WhatsApp Business Manager:</div>
                        <div style={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#4E4E4E'
                        }}>
                            1. Access the <span style={{
                                fontWeight: 600,
                                marginRight: 4
                            }}>
                                WhatsApp Manager &gt; Overview panel at
                            </span>
                            <div onClick={() => window.open('https://business.facebook.com/wa/manage/home/', '_blank')} style={{ color: '#188FFC', cursor: 'pointer' }}>https://business.facebook.com/wa/manage/home/</div>
                        </div>
                        <div style={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#4E4E4E'
                        }}>
                            2. Click the Add payment method button
                        </div>
                        <div style={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#4E4E4E'
                        }}>
                            3. Complete the flow
                        </div>
                    </div>
                </div>
            },
            okay: {
                label: "Submit",
                callback: async () => {
                    setPopupConfirm(null);
                }
            }
        });
    }

    useEffect(() => {
        fbAsyncInit();
        fetchData();
        window.addEventListener('message', onHandleMessage);
        return () => {
            window.removeEventListener('message', onHandleMessage);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (authCode && fbData) {
            onSave();
        }
        //eslint-disable-next-line
    }, [authCode, fbData])

    const onDisconnect = async () => {
        const title = `Disconnect Whatsapp`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Are you sure you want to disconnect<span style={{ fontWeight: "bold", margin: "0px 4px" }}>{phoneNumberId}</span>from Shopletzy?</span></div> },
            yes: {
                label: "Disconnect",
                callback: async () => {
                    setIsLoading(true);
                    await updateChannelSettings('whatsapp', { authCode: '', enabled: false })(dispatch);
                    fetchData();
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    const login = () => {
        WindowObj.FB.login(function (response) {
            if (response.authResponse) {
                if (response.authResponse.code) {
                    setAuthCode(response.authResponse.code);
                }
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: apisUrl.whatsappConfigId,
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                setup: {},
                featureType: '',
                sessionInfoVersion: '3',
            }
        });
    }

    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                </div>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div className="header" style={{ display: 'flex', justifyContent: 'space-between', height: 40 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="Whatsapp" src={WhatsappSvg} style={{ marginRight: 10 }} />WhatsApp
                            </div>
                        </div>
                        <div className="section" style={{ display: 'flex' }}>
                            <div style={{ width: '36vw' }}>
                                <div className="title">Enable Whatsapp</div>
                            </div>
                            <div style={{
                                width: '100%'
                            }}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={wabaId !== null ? true : false}
                                    onClick={(val) => {
                                        setEnabled(val)
                                    }}
                                    checked={enabled}
                                />
                            </div>
                        </div>
                        {enabled && <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex'
                                }}>
                                    <div style={{ width: '50vw' }}>
                                        <div className="title" style={{ marginBottom: 10 }}>WhatsApp</div>
                                        <div style={{ color: "#697b95", width: '72%' }} >To automate replies, allow us to manage
                                            message for your page.</div>
                                        <div style={{ marginTop: 28, color: '#404040', display: 'flex', gap: 10, alignItems: 'center' }}>
                                            {!wabaId && <button onClick={login} style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                padding: "10px 20px",
                                                gap: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                color: '#fff',
                                                border: 'none',
                                                borderRadius: 5,
                                                background: 'linear-gradient(90deg, #21C082 -10.51%, #07C180 229.67%)'
                                            }}>
                                                <img src={WhatsappWhiteSvg} alt="WhatsApp" />
                                                Sign in with WhatsApp
                                            </button>}
                                            {phoneNumberId && <div><span style={{ color: "#697b95" }}>Connect to WhatsApp Business Id: </span>{phoneNumberId}</div>}
                                            {wabaId && <button onClick={onDisconnect} style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                padding: "10px 20px",
                                                gap: 10,
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                color: '#c70039',
                                                border: 'none',
                                                borderRadius: 5,
                                                background: '#fff'
                                            }}>
                                                Disconnect
                                            </button>}
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <div>
                                            <img src={WhatsappBotSvg} alt="WhatsApp Bot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {/* <div
                style={{
                    height: WindowObj.innerHeight - 72,
                    overflowY: 'auto',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <MarkdownViewer fileName={'SettingUpWhatsAppChatbot'} />
            </div> */}
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default Whatsapp;