import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Input, Switch } from "antd";
import InputValidation from '../../../common/InputValidation';
import React, { useEffect, useState } from "react";
import "../../../../../assets/sass/dsk/webhook.sass";
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch } from "react-redux";
import Loading from '../../../common/Loading';
import { getWebhookEvents, createWebhook, updateWebhook } from "../../../../../actions/webhookAction";
import EventsSelector from "./EventsSelector";

dayjs.extend(utc);
const emptyError = {
    url: {
        message: "",
        type: ""
    },
    events: {
        message: "",
        type: ""
    },
    secret: {
        message: "",
        type: ""
    }
};
const URLPattern = /^(https?:\/\/)[\w.-]+(\.[\w.-]+)+(:\d+)?(\/.*)?$/;
const Save = (props: any) => {
    const { isVisible, webhook = null } = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState(null);
    const [events, setEvents] = useState(null);
    const [eventList, setEventList] = useState(null);
    const [secret, setSecret] = useState(null);
    const [activation, setActivation] = useState(true);
    const [error, setError] = useState(JSON.parse(JSON.stringify(emptyError)));
    const [selectedEvents, setSelectedEvents] = useState({});
    const [eventGroups, setEventGroups] = useState({});
    const [isShowSecret, setShowSecret] = useState(false);
    const onSubmit = async () => {
        let errorExists = false;
        let payload = {
            "url": url,
            "secret": secret,
            "events": events,
            "status": activation ? "active" : "disabled"
        }
        if (!isNotNullOrUndefinedString(payload.url)) {
            error.url.message = "Missing url";
            error.url.type = "error";
            errorExists = true;
        } else if (!URLPattern.test(payload.url)) {
            error.url.message = "Invalid url";
            error.url.type = "error";
            errorExists = true;
        } else {
            error.url.message = "";
            error.url.type = "";
        }
        if (!isNotNullOrUndefinedString(payload.secret)) {
            error.secret.message = "Missing secret";
            error.secret.type = "error";
            errorExists = true;
        } else {
            error.secret.message = "";
            error.secret.type = "";
        }
        if (payload.events === null || (payload.events && payload.events.length === 0)) {
            error.events.message = "Missing events";
            error.events.type = "error";
            errorExists = true;
        } else {
            error.events.message = "";
            error.events.type = "";
        }
        setError((prevState) => ({ ...prevState, ...error }));
        if (!errorExists) {
            let resp = null;
            setIsLoading(true);
            if (webhook === null) {
                resp = await createWebhook(payload)(dispatch);
            } else {
                resp = await updateWebhook(webhook.id, { webhook: payload })(dispatch);
            }
            if (resp) {
                onClose(true);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (webhook) {
            setUrl(webhook.url);
            setSecret(webhook.secret);
            setActivation(webhook.status === "active");
            let selectedEvents = {};
            for (let e of webhook.events) {
                selectedEvents[e] = true;
            }
            setSelectedEvents(selectedEvents);
        } else {
            setUrl(null);
            setSecret(null);
            setActivation(true);
            setSelectedEvents({});
        }
    }, [webhook]);

    const onClose = (isSave = false) => {
        setUrl(null);
        setActivation(false);
        setEvents(null);
        setEventList(null);
        setError(JSON.parse(JSON.stringify(emptyError)));
        props.onClose(isSave);
    }
    const loadEvents = async () => {
        const resp = await getWebhookEvents()(dispatch);
        if (resp?.events) {
            setEventList(resp?.events);
        }
    }
    useEffect(() => {
        if (isVisible) {
            loadEvents();
        }
        //eslint-disable-next-line
    }, [isVisible]);

    const onToggleEvent = (event) => {
        setSelectedEvents((prev) => ({
            ...prev,
            [event]: !prev[event],
        }));
    };

    useEffect(() => {
        if (selectedEvents) {
            let events = [];
            for (let key in selectedEvents) {
                if (selectedEvents[key]) {
                    events.push(key);
                }
            }
            setEvents(events);
        }
    }, [selectedEvents]);

    const onToggleGroupEvents = (group) => {
        const allSelected = eventGroups[group].every(({ event }) => selectedEvents[event]);
        setSelectedEvents((prev) => {
            const newSelection = { ...prev };
            eventGroups[group].forEach(({ event }) => {
                newSelection[event] = !allSelected;
            });
            return newSelection;
        });
    };

    useEffect(() => {
        if (eventList) {
            let events = {};
            for (let e of eventList) {
                const eventName = e.event.split('.')[0];
                if (!events[eventName]) {
                    events[eventName] = [];
                } else {
                    events[eventName].push(e);
                }
            }
            setEventGroups(events);
        }
    }, [eventList]);

    return <Drawer
        width={'100%'}
        maskClosable={false}
        closable={false}
        onClose={() => onClose()}
        className="save_webhook"
        open={props.isVisible}
        footer={<div className="footer">
            <div className="sp_btn" onClick={() => onClose()} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
            <div className="sp_btn" onClick={onSubmit} style={{ width: 120, fontSize: 14 }}>{`${webhook === null ? 'Add ' : 'Update '}`}</div>
        </div>}>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={() => onClose()}></ArrowLeftOutlined>
            <div className="title">{`${webhook === null ? 'Add ' : 'Update '}`}Webhook</div>
        </div>
        <div className="content">
            <div className="section-webhook">
                <div className="group">
                    <div className="label">Webhook URL *</div>
                    <InputValidation type={error?.url?.type} message={error?.url?.message}>
                        <Input type="text" name="name" defaultValue={url} placeholder="Enter Url"
                            onChange={(e) => setUrl(e.target.value)} value={url}
                        />
                    </InputValidation>
                </div>
                <div className="group">
                    <div className="label">Secret *</div>
                    <InputValidation type={error?.secret?.type} message={error?.secret?.message}>
                        <Input type={isShowSecret ? "text" : "password"} name="secret key" value={secret} className="secret-key" defaultValue={secret} placeholder="Enter secret key"
                            onChange={(e) => setSecret(e.target.value)} suffix={<div className="text" onClick={() => setShowSecret(!isShowSecret)}>Show Secret</div>}
                        />
                    </InputValidation>
                </div>
                {/* <div className="group">
                    <div className="label">Alert Email</div>
                    <InputValidation type={error?.url?.type} message={error?.url?.message}>
                        <Input type="text" name="name" defaultValue={url} placeholder="Enter Url"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </InputValidation>
                </div> */}
                {webhook && <div className="group">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <div className="label">Activation Status</div>
                        <Switch
                            className="toggle-opt"
                            onClick={() => setActivation(!activation)}
                            checked={activation}
                        />
                    </div>
                    <div className="activation-status">Please ensure that URL is validated, and the Verification Token is implemented on your end before Activating this Webhook.</div>
                </div>}
            </div>
            <div className="section-events">
                <EventsSelector selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} eventGroups={eventGroups} onToggleGroupEvents={onToggleGroupEvents} onToggleEvent={onToggleEvent} />
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </Drawer >
}

export default Save;