import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import Locale from '../common/Locale';
import '../../../assets/sass/dsk/product.sass';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createProduct, uploadFile, updateProduct, deleteFile } from '../../../actions/productActions';
import { getCategories } from '../../../actions/categoryActions';
import { getOutlets } from '../../../actions/outletAction';
// import { getLocale, setLocale } from '../../../actions/uiAction';
import { getL10N } from "../../../helpers/middleware";
import { generateUniqueId, isNotNullOrUndefinedString } from "../../../helpers/util";
// import config from "../../../apiConfig";
import { Popover, Input, Drawer, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
// import FilesUpload from '../common/FilesUpload';
import ArrowIcon from "../../../assets/images/downArrow.svg";
import DeleteIcon from "../../../assets/images/delete.svg";
// import langIcon from "../../../assets/images/language.svg";
import NoImageAvailableIcon from "../../../assets/images/No-image-available.png";
import UploadIcon from "../../../assets/images/gallery-add.svg";
import NoteOutlineSvg from "../../../assets/images/note-outline.svg";
import PopupConfirm from '../common/PopupConfirm';
import InputValidation from '../common/InputValidation';
import Loading from '../common/Loading';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import { uuidv4 } from "../../../helpers/util";
import insert from 'markdown-it-ins';
import { MediaAssets } from '../common/mediaAssets';
import { onUploadMedia } from '../../../actions/commonAction';
import Lexi from '../common/Lexi';
import VariantsCmp from './Variants';

const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(str, lang) { },
}).use(insert);
// const { Option } = Select;
// const text = <span>Language</span>;
const langList = [{
    key: "en",
    value: "English"
}, {
    key: "ta",
    value: "Tamil"
}];
const MarkdownContent = [
    <div key={"MarkdownContent"}>
        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> bold</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>**text**</div></div>
        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px', fontStyle: 'italic' }}> emphasize</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>*text*</div></div>
        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px', textDecoration: 'line-through' }}> Strike through</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>~~To Bold~~</div></div>
        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> Link</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>[title](https://google.com)</div></div>
        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> List</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>*text</div></div>
        {/* <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> Highlight</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>==text==</div></div> */}
    </div>
]
const EditorPlugins = [
    'header',
    'font-bold',
    'font-italic',
    'font-underline',
    'font-strikethrough',
    'list-unordered',
    'list-ordered',
    'block-quote',
    'block-wrap',
    'block-code-inline',
    'block-code-block',
    'table',
    'image',
    'link',
    'tab-insert'
]
const Save = (props: any) => {
    // let action = props.action;
    const windowHeight = window.innerHeight;
    // const [isNew, setIsNew] = useState(props.selectedProduct ? false : true);
    // const categoryList = useSelector((state: any) => state.category.categories);
    const [popupConfirm, setPopupConfirm] = useState(null);
    // const [categories, setCategories] = useState([]);
    // const [categorieOptions, setCategorieOptions] = useState([]);
    // const [tempDeleteImages, setTempDeleteImages] = useState([]);
    const [selectedLang, setSelectedLang] = useState(langList[0]);
    // const [outlets, setOutlets] = useState([]);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState("");
    const [maxItemsPerOrder, setMaxItemsPerOrder] = useState(null);
    const [productLabels, setProductLabels] = useState([]);
    const [isDescPreviewMode, setIsDescPreviewMode] = useState(true);
    const [sku, setSku] = useState("");
    const [isLoadedComp, setLoadedCmp] = useState(false);
    const [hsnCode, setHSNCode] = useState(null);
    // const [variantsDetails, setVariantsDetails] = useState(null);
    const [variants, setVariants] = useState(null);
    const [options, setOptions] = useState(null);
    // const [resetFileUploader, setResetFileUploader] = useState(generateUniqueId("file_uploader"));
    const [taxRate, setTaxRate] = useState(0);
    const [subtitle, setSubTitle] = useState("");
    const [title, setTitle] = useState("");
    const [seoTitle, setSeoTitle] = useState("");
    const [seoDescription, setSeoDescription] = useState("");
    const [slug, setSlug] = useState("");
    const [product, setProduct] = useState({ id: null, title: "", subtitle: "", l10n: {}, sku: "", outlets: [], variants: null });
    const [addonGroups, setAddonGroups] = useState([]);
    const [selectedAddonGroup, setSelectedAddonGroup] = useState(null);
    const [stopAddonGroup, setStopAddonGroup] = useState(false);
    const [salePrice, setSalePrice] = useState(0);
    const [price, setPrice] = useState(0);
    // const [visibleTitleLang, setVisibleTitleLang] = useState(false);
    // const [visibleSubTitleLang, setVisibleSubTitleLang] = useState(false);
    let errorInfo = {
        minSelection: {
            message: "",
            type: ""
        },
        maxSelection: {
            message: "",
            type: ""
        },
        groupName: {
            message: "",
            type: ""
        },
        addonName: {
            message: "",
            type: ""
        },
        sku: {
            message: "",
            type: ""
        },
        hsnCode: {
            message: "",
            type: ""
        },
        maxItemsPerOrder: {
            message: "",
            type: ""
        },
        title: {
            message: "",
            type: ""
        },
        subtitle: {
            message: "",
            type: ""
        },
        categories: {
            message: "",
            type: ""
        }
    };
    const [error, setError] = useState(errorInfo);
    const [productLang, setProductLang] = useState({
        subtitleLang: langList[0].key,
        titleLang: langList[0].key,
    });

    const [isEdit, setIsEdit] = useState(false);


    // const [subtitleLang, setSubtitleLang] = useState(selectedLang.key);
    // const [titleLang, setTitleLang] = useState(selectedLang.key);

    // useEffect(() => {
    //     let categoryOptions = []
    //     for (let i in categoryList) {
    //         let searchRegExp = /\//g;
    //         let replaceWith = ' / ';
    //         categoryOptions.push(<Option key={categoryList[i].category.replace("/", "")} value={categoryList[i].category} >{categoryList[i].category.replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase())}</Option>)
    //     }
    //     setCategorieOptions(categoryOptions);
    // }, [categoryList]);

    // const loadOutlets = async () => {
    //     let respOutlets = await props.getOutlets();
    //     if (respOutlets) {
    //         setOutlets(respOutlets);
    //     } else {
    //         setOutlets([]);
    //     }
    // }

    const resetError = () => {
        setError({
            minSelection: {
                message: "",
                type: ""
            },
            maxSelection: {
                message: "",
                type: ""
            },
            groupName: {
                message: "",
                type: ""
            },
            addonName: {
                message: "",
                type: ""
            },
            sku: {
                message: "",
                type: ""
            },
            hsnCode: {
                message: "",
                type: ""
            },
            maxItemsPerOrder: {
                message: "",
                type: ""
            },
            title: {
                message: "",
                type: ""
            },
            subtitle: {
                message: "",
                type: ""
            },
            categories: {
                message: "",
                type: ""
            }
        });
    }

    const onCreateAddonGroup = () => {
        resetError();
        const newAddon = {
            "id": generateUniqueId("addon"),
            "title": "",
            "price": 0,
            "taxRate": 0
        };
        setSelectedAddonGroup({
            id: null,
            title: "",
            mandatory: false,
            minSelection: 0,
            maxSelection: 1,
            addons: [newAddon]
        })
    }

    useEffect(() => {
        if (props.selectedProduct) {
            if (props.selectedProduct.id) {
                // loadOutlets();
                setProduct(props.selectedProduct);
                setTitle(getL10N(props.selectedProduct, "title", selectedLang).i10n);
                setSubTitle(getL10N(props.selectedProduct, "subtitle", selectedLang).i10n);
                if (props.selectedProduct.seoTitle) {
                    setSeoTitle(props.selectedProduct.seoTitle);
                }
                if (props.selectedProduct.seoDescription) {
                    setSeoDescription(props.selectedProduct.seoDescription);
                }
                if (props.selectedProduct.slug) {
                    setSlug(props.selectedProduct.slug);
                }
                if (props.selectedProduct.sku) {
                    setSku(props.selectedProduct.sku);
                }
                if (props.selectedProduct.description) {
                    setSummary(props.selectedProduct.description);
                }
                if (props.selectedProduct.maxItemsPerOrder) {
                    setMaxItemsPerOrder(props.selectedProduct.maxItemsPerOrder)
                } else {
                    setMaxItemsPerOrder(null);
                }
                if (props.selectedProduct.taxRate) {
                    setTaxRate(props.selectedProduct.taxRate)
                } else {
                    setTaxRate(0)
                }
                if (props.selectedProduct.hsnCode) {
                    setHSNCode(props.selectedProduct.hsnCode)
                } else {
                    setHSNCode(null);
                }
                if (props.selectedProduct.price) {
                    setPrice(props.selectedProduct.price)
                } else {
                    setPrice(0);
                }
                if (props.selectedProduct.salePrice) {
                    setSalePrice(props.selectedProduct.salePrice)
                } else {
                    setSalePrice(0);
                }
                if (props.selectedProduct.images) {
                    setImages(props.selectedProduct.images);
                }
                // if (props.selectedProduct.categories) {
                //     setCategories(props.selectedProduct.categories);
                // } else {
                //     setCategories([]);
                // }
                if (props.selectedProduct.labels) {
                    setProductLabels(props.selectedProduct.labels);
                }
                if (props.selectedProduct.addonGroups) {
                    setAddonGroups(props.selectedProduct.addonGroups);
                }
                if (props.selectedProduct.defaultVariantDetails.variants) {
                    setVariants(props.selectedProduct.defaultVariantDetails.variants);
                }
                if (props.selectedProduct.defaultVariantDetails.options) {
                    setOptions(props.selectedProduct.defaultVariantDetails.options);
                }
            } else {
                setVariants(prev => { return prev ? prev : [] });
                setOptions(prev => { return prev ? prev : [] });
            }
        }
        //eslint-disable-next-line
    }, [props.selectedProduct]);


    // useEffect(() => {
    //     console.log(":::::::::::::: op v", options, variants)
    // }, [options]);
    // useEffect(() => {
    //     if (props.defaultVariantDetails && props.defaultVariantDetails.variants && props.defaultVariantDetails.options) {
    //         console.log(":::::::::::::: defaultVariantDetails", props.defaultVariantDetails)
    //         setVariants(props.defaultVariantDetails.variants);
    //         setOptions(props.defaultVariantDetails.options);
    //     }
    // }, [props.defaultVariantDetails]);

    // useEffect(() => {
    //     if (props.selectedProduct.variants) {
    //         setVariants(props.selectedProduct.variants);
    //     } else {
    //         setVariants([]);
    //     }
    //     if (props.selectedProduct.options) {
    //         const formattedData = props.selectedProduct.options.map(attribute => ({
    //             id: uuidv4(),
    //             name: attribute.name,
    //             values: attribute.values.map(value => ({
    //                 id: uuidv4(),
    //                 name: value
    //             }))
    //         }));
    //         setOptions(formattedData);
    //     }
    // },[defaultVariantDetails]);

    // useEffect(() => {
    //     if (options && variants) {
    //         setDefaultVariantDetails({
    //             variants,
    //             options
    //         });
    //     }
    // }, [options, variants]);

    // useEffect(() => {
    //     // console.log(productLang);
    // }, [productLang]);

    const renderLang = (fieldName) => {
        const content = [];
        for (let i in langList) {
            // console.log(productLang[`${fieldName}Lang`])
            content.push(
                <div key={langList[i].key} style={{ color: productLang[`${fieldName}Lang`] === langList[i].key ? "#37a2fd" : "#000000", fontWeight: productLang[`${fieldName}Lang`] === langList[i].key ? 800 : 100 }}>
                    <p onClick={() => onChangeLang(langList[i], fieldName)}>{langList[i].value}</p>
                </div>
            );
        }
        return content;
    }

    const onChangeLang = (selectLang, fieldName) => {
        // console.log(selectLang, fieldName)
        if (product) {
            let { i10n, isDefault } = getL10N(product, fieldName, selectLang);
            if (fieldName === "title") {
                setTitle(isDefault ? "" : i10n);
            }
            if (fieldName === "subtitle") {
                setSubTitle(isDefault ? "" : i10n);
            }
        }
        // let updateLang = { [`${fieldName}Lang`]: selectedLang.key } };
        // console.log(updateLang)
        setProductLang((preState) => ({ ...preState, ...{ [`${fieldName}Lang`]: selectLang.key } }));
        // setVisibleTitleLang(false);
        // setVisibleSubTitleLang(false);
        setSelectedLang((preState) => ({ ...preState, ...selectLang }))
    }



    // const onChangeCategory = (value) => {
    //     setCategories(value)
    // }

    const onSubmit = () => {
        const errorItem = validateAddonGroup(addonGroups);
        if (errorItem) {
            setSelectedAddonGroup(errorItem);
            setSelectedTabMenu("addon");
            return
        }
        // const {
        //     options,
        //     variants
        // } = variantsDetails;
        let formattedOptions = [];
        if (options && options.length > 0) {
            formattedOptions = options.map(item => ({
                name: item.name,
                values: item.values.map(value => value.name)
            }));
        }

        let popupConfirmDetail = null;
        let isError = false;
        let payload = {
            title: "",
            subtitle: "",
            seoTitle: "",
            seoDescription: "",
            images,
            slug: "",
            // categories: null,
            taxRate: 0,
            hsnCode: hsnCode ? parseInt(hsnCode) : hsnCode,
            sku: "",
            labels: [],
            price: 0,
            salePrice: 0,
            addonGroups: []
        };

        // this.setState({ error });
        if (hsnCode && hsnCode < 10) {
            errorInfo.hsnCode.message = "Enter the valid hsn code";
            errorInfo.hsnCode.type = "error";
            isError = true;
        } else {
            if (!hsnCode) {
                delete payload.hsnCode;
            }
            errorInfo.hsnCode.message = "";
            errorInfo.hsnCode.type = "";
        }

        if (!product.id && !isNotNullOrUndefinedString(sku)) {
            errorInfo.sku.message = "Enter the SKU details";
            errorInfo.sku.type = "error";
            isError = true;
        } else {
            errorInfo.sku.message = "";
            errorInfo.sku.type = "";
        }

        if (!isNotNullOrUndefinedString(product.title)) {
            errorInfo.title.message = "Enter the product title";
            errorInfo.title.type = "error";
            isError = true;
        } else {
            errorInfo.title.message = "";
            errorInfo.title.type = "";
        }
        if (!isNotNullOrUndefinedString(product.subtitle)) {
            errorInfo.subtitle.message = "Enter the product sub title";
            errorInfo.subtitle.type = "error";
            isError = true;
        } else {
            errorInfo.subtitle.message = "";
            errorInfo.subtitle.type = "";
        }

        // if (categories.length === 0) {
        //     errorInfo.categories.message = "Select any one of category";
        //     errorInfo.categories.type = "error";
        //     isError = true;
        // } else {
        //     errorInfo.categories.message = "";
        //     errorInfo.categories.type = "";
        // }
        if (maxItemsPerOrder && maxItemsPerOrder < 1) {
            errorInfo.maxItemsPerOrder.message = "Max items per order greater than zero";
            errorInfo.maxItemsPerOrder.type = "error";
            isError = true;
        } else {
            errorInfo.maxItemsPerOrder.message = "";
            errorInfo.maxItemsPerOrder.type = "";
        }
        setError((preState) => ({ ...preState, ...errorInfo }));
        if (!isError) {
            payload.title = product.title;
            payload.taxRate = taxRate;
            payload.subtitle = product.subtitle;
            payload.labels = productLabels;
            payload.seoTitle = seoTitle;
            payload.seoDescription = seoDescription;
            payload.slug = slug;
            payload.price = price;
            payload.salePrice = salePrice;
            payload.addonGroups = clearNewAddonGroupIds(JSON.parse(JSON.stringify(addonGroups)));
            if (maxItemsPerOrder && maxItemsPerOrder > 0) {
                payload['maxItemsPerOrder'] = parseInt(maxItemsPerOrder);
            }
            if (summary) {
                payload["description"] = summary;
            }
            if (product.l10n) {
                payload["l10n"] = product.l10n;
            }
            payload.sku = sku;
            // payload.categories = categories;
            // if (product.id) {
            //     payload["outlets"] = product.outlets;
            //     this.uploadImages(product.id);
            // } else {
            //     if (this.props.common.selectedOutlets) {
            //         payload["outlets"] = this.props.common.selectedOutlets
            //     }
            // }          
            if (formattedOptions.length > 0) {
                payload["options"] = formattedOptions;
            }
            if (variants && variants.length > 0) {
                payload["variants"] = variants.filter((item) => !item.isDeleted);
            }
            // console.log(JSON.stringify(payload))
            if (product.id) {
                // console.log("variants:::::::::::: ", product.variants)
                // const { updates, adds, deletes } = variantDifferent(variants, product.variants);
                // console.log({ updates, adds, deletes })
                // payload["variants"] = {
                //     adds,
                //     updates,
                //     deletes
                // };
                // return;
                delete payload.sku;
                popupConfirmDetail = {
                    title: "Update Product",
                    renderContent: () => { return <div>Do you like to update {product.title} SKU <span style={{ fontWeight: 900, font: "14px AvenirMedium", letterSpacing: "0px", color: "#34E5C1" }}>{product.sku}</span></div> },
                    yes: {
                        label: "Update Product",
                        callback: () => { onSaveProduct(product.id, payload); }
                    },
                    no: {
                        label: "May be next time",
                        callback: () => { setPopupConfirm(null) }
                    }
                }
                setPopupConfirm(popupConfirmDetail)
            } else {
                popupConfirmDetail = {
                    title: "Add Product",
                    renderContent: () => { return <div>Do you like to add new {product.title} SKU <span style={{ fontWeight: 900, font: "14px AvenirMedium", letterSpacing: "0px", color: "#34E5C1" }}>{product.sku}</span></div> },
                    yes: {
                        label: "Add Product",
                        callback: () => { onSaveProduct(null, payload); }
                    },
                    no: {
                        label: "May be next time",
                        callback: () => { setPopupConfirm(null) }
                    }
                }
            }
            setPopupConfirm(popupConfirmDetail)
        } else {
            setSelectedTabMenu("product_specification");
        }
    }

    const onSaveProduct = async (pId, payload) => {
        setIsLoading(true);
        setPopupConfirm(null);
        // console.log({ pId, payload })
        let resp = null;
        if (pId) {
            resp = await props.updateProduct(pId, payload)
        } else {
            resp = await props.createProduct(payload);
        }
        setIsLoading(false);
        if (resp?.isUpdated || resp?.productId) {
            props.onClose();
        }
    }

    const setProductLocale = (key, val, lang) => {
        if (!product) {
            // setProduct((preState) => ({}))
            setProduct((preState) => ({ ...preState, ...{} }));
        }
        if (lang === "en") {
            if (product) {
                if (!product[key]) {
                    product[key] = {}
                }
                product[key] = val;
            }
        } else {
            if (!product.l10n) {
                product.l10n = {};
            }
            if (!product.l10n[lang]) {
                product.l10n[lang] = {}
            }
            if (!product.l10n[lang][key]) {
                product.l10n[lang][key] = {}
            }
            product.l10n[lang][key] = val;
        }
        setProduct(product)
    }

    useEffect(() => {
        if (props.isVisible === false || props.action === "new") {
            clearVal();
        }
        if (props.isVisible === true) {
            // setVisibleTitleLang(false);
            // setVisibleSubTitleLang(false);
            setIsEdit(props.action === "new" ? true : false);
            props.getCategories();
        } else {
            setIsEdit(false);
            setPopupConfirm(null);
            setError(errorInfo);
        }
        //eslint-disable-next-line
    }, [props.isVisible]);
    const clearVal = () => {
        // setResetFileUploader(generateUniqueId("file_uploader"));
        setPopupConfirm(null);
        // setCategories([]);
        // setCategorieOptions([]);
        setSelectedLang(langList[0]);
        // setOutlets([]);
        setImages([]);
        setSummary("");
        setMaxItemsPerOrder(null);
        setSku("");
        setSubTitle("");
        setSlug("");
        setSeoTitle("");
        setSalePrice(0);
        setPrice(0);
        setSeoDescription("");
        setTitle("");
        setProductLabels([]);
        setTaxRate(0);
        setStopAddonGroup(false);
        setAddonGroups([]);
        setSelectedAddonGroup(null);
        // setVariantsDetails(null);
        setOptions(null);
        setVariants(null);
        // setDefaultVariantDetails({
        //     options: null,
        //     variants: null
        // });
        setProduct({ id: null, title: "", subtitle: "", l10n: {}, sku: "", outlets: [], variants: null });
    }
    // const renderOutletName = (ouId) => {
    //     let getOutletInfo = outlets.filter((item) => (item.id === ouId));
    //     return getOutletInfo.length > 0 ? getOutletInfo[0].name : "Not Available";
    // }
    // const handleEditorChange = ({ html, text }) => {
    //     setSummary(text)
    // }
    const addNewAddonGroup = () => {
        const errorItem = validateAddonGroup([selectedAddonGroup]);
        if (!errorItem) {
            const newAddonGroup = {
                ...selectedAddonGroup,
                ...{ id: generateUniqueId("addon_group") }
            }
            setAddonGroups((prevState) => ([
                ...prevState,
                newAddonGroup
            ]));
            setSelectedAddonGroup(null);
        }
    };
    const addNewAddon = () => {
        const newAddon = {
            "id": generateUniqueId("addon"),
            "title": "",
            "price": 0,
            "taxRate": 0
        };
        setSelectedAddonGroup((prevState) => ({
            ...prevState,
            addons: [...prevState.addons, newAddon]
        }));
    };
    const updateAddon = (addonIndex, key, value) => {
        const selectAddon = selectedAddonGroup.addons[addonIndex];
        selectAddon[key] = value;
        setSelectedAddonGroup((prevState) => ({
            ...prevState,
            addons: prevState.addons.map((addon, i) => (i === addonIndex ? selectAddon : addon))
        }));
    };
    const removeAddon = (index) => {
        // console.log({ index, item })
        // console.log(selectedAddonGroup)
        setSelectedAddonGroup((prevState) => ({
            ...prevState,
            addons: prevState.addons.filter((addon, i) => (i !== index))
        }));
    }
    const removeAddonGroup = (removeItem) => {
        setAddonGroups((prevItems) => prevItems.filter((item) => item.id !== removeItem.id));
    }
    useEffect(() => {
        if (selectedAddonGroup) {
            setAddonGroups((prevItems) =>
                prevItems.map((item: any) => {
                    return item.id === selectedAddonGroup.id ? selectedAddonGroup : item
                })
            );
        }
    }, [selectedAddonGroup]);
    const isExistAddonGroup = (name, id) => {
        let isExist = false;
        for (let item of addonGroups) {
            if (item.title === name && item.id !== id) {
                isExist = true;
                break;
            }
        }
        return isExist;
    }
    const isExitAddon = (name, id) => {
        let isExist = false;
        for (let addonGroup of addonGroups) {
            for (let addon of addonGroup.addons) {
                if (addon.title === name && addon.id !== id) {
                    isExist = true;
                    break;
                }
            }
        }
        return isExist;
    }
    const clearNewAddonGroupIds = (addonGroupList) => {
        for (let addonGroup of addonGroupList) {
            if (addonGroup?.id?.indexOf("addon_group") > -1) {
                delete addonGroup.id;
            }
            for (let addon of addonGroup.addons) {
                if (addon?.id?.indexOf("addon") > -1) {
                    delete addon.id;
                }
            }
        }
        return addonGroupList;
    }
    const validateAddonGroup = (addonGroupList) => {
        let isErrorItem = null;
        for (let addonGroup of addonGroupList) {
            if (addonGroup.title.length <= 2) {
                isErrorItem = addonGroup;
                errorInfo.groupName.type = "error";
                errorInfo.groupName.message = "Group name minimum 3 characters";
            } else if (addonGroup.title.length > 200) {
                isErrorItem = addonGroup;
                errorInfo.groupName.type = "error";
                errorInfo.groupName.message = "Group name maximum 200 characters";
            } else if (isExistAddonGroup(addonGroup.title, addonGroup.id)) {
                isErrorItem = addonGroup;
                errorInfo.groupName.type = "error";
                errorInfo.groupName.message = "Group name already exist";
            } else {
                errorInfo.groupName.type = "";
                errorInfo.groupName.message = "";
            }
            if (addonGroup.mandatory) {
                if (parseInt(addonGroup.minSelection) === 0) {
                    errorInfo.minSelection.type = "error";
                    errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                    isErrorItem = addonGroup;

                } else {
                    errorInfo.minSelection.type = "";
                    errorInfo.minSelection.message = "";
                }
            }
            if (parseInt(addonGroup.maxSelection) < parseInt(addonGroup.minSelection)) {
                errorInfo.maxSelection.type = "error";
                errorInfo.maxSelection.message = "Maximum selection should be great than minimum selection";
                isErrorItem = addonGroup;

            } else {
                errorInfo.maxSelection.type = "";
                errorInfo.maxSelection.message = "";
            }
            for (let addon of addonGroup.addons) {
                if (addon.title.length <= 2) {
                    errorInfo.addonName.type = "error";
                    errorInfo.addonName.message = `${addon.title} Addon name minimum 3 characters`;
                    isErrorItem = addonGroup;

                } else if (addon.title.length > 200) {
                    errorInfo.addonName.type = "error";
                    errorInfo.addonName.message = `${addon.title} Addon name maximum 200 characters`;
                    isErrorItem = addonGroup;

                } else if (isExitAddon(addon.title, addon.id)) {
                    isErrorItem = addonGroup;
                    errorInfo.addonName.type = "error";
                    errorInfo.addonName.message = `${addon.title} Group name already exist`;
                } else {
                    errorInfo.addonName.type = "";
                    errorInfo.addonName.message = "";
                }
                if (isErrorItem) {
                    break;
                }
            }
            if (isErrorItem) {
                break;
            }
        }
        setStopAddonGroup(isErrorItem ? true : false);
        setError((preState) => ({ ...preState, ...errorInfo }));
        return isErrorItem;
    }
    const onUploadFiles = (fileList, disableImageTransform) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            if (!disableImageTransform) {
                formData.append('imageTransform', 'w:500');
            }
            const resp = await props.onUploadMedia(formData);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    urls = resp.map((r) => {
                        return r.files && r.files.length > 0 && r.files[0]
                    });
                    setImages([...images, ...urls]);
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }
    const loadImages = () => {
        let imageList = [];
        for (let url of images) {
            imageList.push({
                url,
                id: uuidv4()
            })
        }
        return imageList;
    }
    const TabMenu = [
        { label: "Product Specification", value: "product_specification" },
        { label: "Addons", value: "addons" },
        { label: "Variants", value: "variants" }
    ];
    const [selectedTabMenu, setSelectedTabMenu] = useState("product_specification");
    const onSelectedTabMenu = (value) => {
        setSelectedTabMenu(value);
    }
    const onGenerateVariantOptions = () => {
        const optionNames = options.map(option => option.name);
        const optionValues = options.map(option => option.values.map(value => value.name));
        const cartesianProduct = optionValues.reduce((acc, curr) =>
            acc.flatMap(a => curr.map(c => [...a, c])), [[]]
        );
        return cartesianProduct.map(values => {
            const variant = { price: 0, salePrice: 0, sku: "", media: [], options: {} };
            values.forEach((value, index) => {
                variant.options[optionNames[index]] = value;
            });
            return variant;
        });
    }


    const normalizeOptions = (options) => {
        return Object.keys(options).reduce((normalized, key) => {
            normalized[key] = options[key];
            return normalized;
        }, {});
    }

    const addMissingVariantOptions = (data1, data2) => {
        // console.log("DATA1 ", JSON.stringify(data1))
        // console.log("DATA2 ", JSON.stringify(data2))
        const normalizedData1Options = data1.map(item => item.options && normalizeOptions(item.options));
        data2.forEach(item => {
            const normalizedOption = normalizeOptions(item.options);
            const existsInData = normalizedData1Options.some(
                option => JSON.stringify(option) === JSON.stringify(normalizedOption)
            );
            if (!existsInData) {
                item.isDeleted = true;
                if (product?.id && !isLoadedComp) {
                    item.price = 0;
                    item.salePrice = 0;
                } else {
                    if (price) {
                        item.price = price;
                    }
                    if (salePrice) {
                        item.salePrice = salePrice;
                    }
                }
                data1.push({
                    ...item,
                    options: { ...item.options }
                });
            }
        });
        const filteredData = data1.filter(item1 =>
            data2.some(item2 =>
                JSON.stringify(item1.options) === JSON.stringify(item2.options)
            )
        );
        setLoadedCmp(true);
        return filteredData;
    }
    useEffect(() => {
        if (options && options.length > 0 && variants && variants.length > 0) {
            const copyVariants = JSON.parse(JSON.stringify(variants));
            const generateVariantOptions = onGenerateVariantOptions();
            const updatedMissingVariantOptions = addMissingVariantOptions(copyVariants, generateVariantOptions);
            setVariants(updatedMissingVariantOptions);
        } else if (options && options.length === 1 && (variants === null || variants.length === 0)) {
            let variantOptions = [];
            for (let optVal of options[0].values) {
                const vOption = { price, salePrice, sku: "", media: [], options: {} };
                vOption.options[options[0].name] = optVal.name
                variantOptions.push(vOption)
            }
            setVariants(variantOptions);
        }
        //eslint-disable-next-line
    }, [options]);

    // console.log(":::::Final::::: ", JSON.stringify(variants, null, 2))
    return (
        <Drawer
            width={'100vw'}
            closable={false}
            onClose={() => { setError(errorInfo); props.onClose(); }}
            className="product-view"
            visible={props.isVisible}
            footer={
                <div className='footer'>
                    <div className="sp_btn" onClick={() => { setError(errorInfo); props.onClose(); }} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                    {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true); setIsDescPreviewMode(false); }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                    {isEdit && <div className="sp_btn" onClick={onSubmit} style={{ width: 155, fontSize: 14 }}>{props?.selectedProduct?.id ? "Update Product" : "Create Product"}</div>}
                </div>
            }>
            {isLoading && <Loading background="#00000070"></Loading>}
            {/* <div className="header">
                    <div>
                        <div className="link" onClick={this.gotoProducts}>Products</span> <RightOutlined className="nav" /> <div className="head">{this.state.isNew ? "Add" : "Update"}</span>
                    </div>
                </div> */}
            <div className="header">
                <ArrowLeftOutlined className="arrow" onClick={() => { setError(errorInfo); props.onClose(); }}></ArrowLeftOutlined>
                <div className="title">Product Details</div>
            </div>
            <div className="content" style={{ background: "#f1f1f1" }}>
                <div className="tab-menus">
                    {TabMenu && TabMenu.map((tMenu) =>
                        <div key={tMenu.value} className={selectedTabMenu === tMenu.value ? "label selected" : "label"} onClick={() => onSelectedTabMenu(tMenu.value)}>
                            {tMenu.label}
                            {selectedTabMenu === tMenu.value && <div className="underline"></div>}
                        </div>)}
                </div>
                <div className="details" style={{ height: windowHeight - 188, overflow: 'auto', paddingTop: 30 }}>
                    {/* <div className="basic-info">
                        <div style={{ textTransform: "uppercase" }}>
                            <ArrowLeftOutlined style={{
                                position: "absolute",
                                cursor: "pointer",
                                marginLeft: "-44px"
                            }} onClick={() => props.onClose()}></ArrowLeftOutlined>
                            Product Details
                        </div>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <div style={{
                            width: 950,
                            marginRight: 12,
                            display: (selectedTabMenu === "product_specification" || selectedTabMenu === "addons") ? 'block' : 'none',
                        }}>
                            <div style={{
                                display: selectedTabMenu === "product_specification" ? 'flex' : 'none',
                                flexDirection: 'column',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#FFF',
                                    marginBottom: 12,
                                    border: "1px solid #D9D9D9", borderRadius: 8, boxShadow: "0px 4px 16px 0px #C6C6C61F"
                                }}>

                                    <div className="group">
                                        <div>
                                            <div className="label" >Title *</div>
                                        </div>
                                        <div>
                                            <InputValidation type={error.title.type} message={error.title.message}>
                                                <Input className="value" style={{ width: 749 }}
                                                    // suffix={
                                                    //     <Popover placement="rightTop" visible={visibleTitleLang} onVisibleChange={() => {
                                                    //         setVisibleTitleLang(!visibleTitleLang);
                                                    //         setVisibleSubTitleLang(false)
                                                    //     }} title={text} content={renderLang("title")} trigger="click">
                                                    //         <img src={langIcon} />
                                                    //     </Popover>
                                                    // }
                                                    value={title} disabled={!isEdit} onChange={(e) => {
                                                        setTitle(e.target.value);
                                                        setSku(e.target.value.replace(/ /g, "_").toLocaleUpperCase());
                                                        setProductLocale("title", e.target.value, productLang.titleLang);
                                                        renderLang("title");
                                                    }} />
                                            </InputValidation>
                                        </div>
                                    </div>
                                    <div className="group">
                                        <div>
                                            <div className="label" >Sub Title *</div>
                                        </div>
                                        <div>
                                            <InputValidation type={error.subtitle.type} message={error.subtitle.message}>

                                                <Input className="value" style={{ width: 749 }}
                                                    // suffix={
                                                    //     <Popover placement="rightTop" visible={visibleSubTitleLang} onVisibleChange={() => {
                                                    //         setVisibleTitleLang(false);
                                                    //         setVisibleSubTitleLang(!visibleSubTitleLang)
                                                    //     }} title={text} content={renderLang("subtitle")} trigger="click">
                                                    //         <img src={langIcon} />
                                                    //     </Popover>
                                                    // } 
                                                    disabled={!isEdit} value={subtitle} onChange={(e) => { setSubTitle(e.target.value); setProductLocale("subtitle", e.target.value, productLang.subtitleLang) }} />
                                            </InputValidation>
                                        </div>
                                    </div>
                                    <div className="group">
                                        <div>
                                            <div className="label" >Slug</div>
                                        </div>
                                        <div>
                                            <Input className="value" style={{ width: 749 }}
                                                disabled={!isEdit} value={slug} onChange={(e) => { setSlug(e.target.value); }} />
                                        </div>
                                    </div>
                                    <div className="group">
                                        <div>
                                            <div style={{ width: 749, display: 'flex', justifyContent: "space-between" }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <div className="label" >Description *</div>
                                                    {isEdit && <Lexi uesFor='productDescription' callback={setSummary} title='Generate Product description' />}
                                                </div>
                                                {isEdit && <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Popover style={{
                                                        background: '#FFFFFF',
                                                        boxShadow: '0px 8px 24px #00000029',
                                                        border: '1px solid #CBCBCB',
                                                        height: "auto",
                                                        width: "auto",
                                                    }} placement="rightTop" content={MarkdownContent} trigger="click">
                                                        <div style={{ borderRadius: 4, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 19, height: 19, background: '#909090', color: '#FFF' }}>
                                                            i
                                                        </div>
                                                    </Popover>
                                                    <div style={{
                                                        fontSize: 14,
                                                        height: 31,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: 10,
                                                    }}>
                                                        Preview
                                                        <Switch className="toggle-opt" disabled={!isEdit} style={{ marginLeft: 10 }} checked={isDescPreviewMode} onChange={(e) => { setIsDescPreviewMode(e) }} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div>
                                            {!isDescPreviewMode ? <TextArea style={{ width: 749, marginTop: 6 }} className="value" disabled={!isEdit} value={summary} onChange={(e) => setSummary(e.target.value)} rows={12} /> :
                                                <div className='markdown' style={{ width: 749 }}>
                                                    <div className='preview'>
                                                        <MdEditor
                                                            readOnly={!isEdit}
                                                            plugins={EditorPlugins}
                                                            config={{
                                                                view: {
                                                                    menu: false,
                                                                    md: false,
                                                                    html: true,
                                                                    fullScreen: false,
                                                                    hideMenu: false,
                                                                }
                                                            }}
                                                            value={summary}
                                                            placeholder="Enter the summary"
                                                            style={{ height: 160, width: 749 }}
                                                            renderHTML={(text) => mdParser.render(text)}
                                                        />
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    background: '#FFF',
                                    alignItems: 'center',
                                    marginBottom: 12,
                                    border: "1px solid #D9D9D9", borderRadius: 8, boxShadow: "0px 4px 16px 0px #C6C6C61F"
                                }}>
                                    <div className="group">
                                        <div style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>SEO</div>
                                        <div>
                                            <div className="label">Title</div>
                                        </div>
                                        <div>
                                            <Input className="value" style={{ width: 749 }}
                                                disabled={!isEdit} value={seoTitle} onChange={(e) => { setSeoTitle(e.target.value); }} />
                                        </div>
                                    </div>
                                    <div className="group">
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}>
                                            <div className="label">Description</div>
                                            {isEdit && <Lexi uesFor='seoDescription' callback={setSeoDescription} title='Generate SEO description' />}
                                        </div>
                                        <div>
                                            <TextArea className="value" style={{ width: 749 }}
                                                disabled={!isEdit} value={seoDescription} onChange={(e) => { setSeoDescription(e.target.value); }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    background: '#FFF',
                                    alignItems: 'center',
                                    marginBottom: 12,
                                    border: "1px solid #D9D9D9", borderRadius: 8, boxShadow: "0px 4px 16px 0px #C6C6C61F"
                                }}>
                                    <div className="group" >
                                        <div>
                                            <div className="label" style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }} >Media</div>
                                        </div>
                                        {/* height: windowHeight - 580, */}
                                        <div style={{ marginTop: 12, width: 749, paddingTop: 10 }}>
                                            {!isEdit && images.length === 0 && <div style={{ width: 120, display: 'flex', justifyContent: 'center' }}>
                                                <img alt='Not Available' src={NoImageAvailableIcon} style={{ height: 74 }} />
                                            </div>}
                                            <MediaAssets isEdit={isEdit} multiple={true}
                                                key="product-images"
                                                defaultValue={loadImages()}
                                                onSort={(items) => {
                                                    let urls = items.map((item) => {
                                                        return item.url
                                                    });
                                                    setImages([...urls]);
                                                }}
                                                uploadIcon={UploadIcon}
                                                onSubmit={async ({ fileList, selectedPreIcon, disableImageTransform }) => {
                                                    let urls = [];
                                                    if (selectedPreIcon && selectedPreIcon.length > 0) {
                                                        urls = selectedPreIcon.map((item) => {
                                                            return item.url
                                                        });
                                                    }
                                                    if (fileList && fileList.length > 0) {
                                                        onUploadFiles(fileList, disableImageTransform)
                                                    } else {
                                                        setImages([...images, ...urls]);
                                                    }
                                                }}
                                                onRemove={(item) => {
                                                    let newImages = images.filter((img) => img !== item.url);
                                                    setImages(newImages);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: selectedTabMenu === "addons" ? 'flex' : 'none',
                                flexDirection: 'column',
                                background: '#FFF',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: "1px solid #D9D9D9",
                                borderRadius: 8,
                                lineHeight: 'normal',
                                boxShadow: "0px 4px 16px 0px #C6C6C61F"
                            }}>
                                {<div className="group" style={{ margin: 0 }}>
                                    {((addonGroups && addonGroups.length > 0) || (selectedAddonGroup)) && <div style={{
                                        display: 'flex',
                                        width: 749,
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderBottom: `${addonGroups.length > 0 ? 1 : 0}px solid #D9D9D9`,
                                        paddingBottom: addonGroups.length > 0 ? 16 : 0,
                                        marginBottom: addonGroups.length > 0 ? 16 : 0,
                                        marginTop: 16,
                                        // marginBottom: addonGroups.length === 0 || (selectedAddonGroup && !selectedAddonGroup?.id) ? 0 : 16,
                                    }}>
                                        <div className="label" style={{ fontWeight: 600, fontSize: 18, margin: 0, lineHeight: 'normal' }} >Add-on Groups</div>
                                        <div>
                                            {(!selectedAddonGroup || (selectedAddonGroup && selectedAddonGroup?.id)) && isEdit && <div onClick={onCreateAddonGroup} style={{
                                                borderRadius: 4,
                                                padding: "4px 10px",
                                                cursor: isEdit ? "pointer" : "default",
                                                border: "1px solid #D9D9D9",
                                                background: "#FFFFFF",
                                            }} >+Add-on Groups</div>}
                                            {selectedAddonGroup && !selectedAddonGroup?.id &&
                                                <div style={{ margin: "10px 0px", display: 'flex', alignItems: 'center' }}>
                                                    <div className='sp_btn' onClick={addNewAddonGroup} style={{ width: 80, height: 32, marginRight: 16, cursor: "pointer" }}>Submit</div>
                                                    <div onClick={() => setSelectedAddonGroup(null)} style={{ cursor: 'pointer' }}>Cancel</div>
                                                </div>
                                            }
                                        </div>
                                    </div>}
                                    {selectedAddonGroup && !selectedAddonGroup?.id && <div style={{ marginTop: 15 }}>
                                        <div>
                                            <div className="label">
                                                Group Name *
                                            </div>
                                            <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between' }}>
                                                {/* <InputValidation type={error.title.type} message={error.title.message}>
                                                    <Input className="value" value={selectedAddonGroup.title} style={{ width: 750 }} onChange={(e) => { setSelectedAddonGroup((preState) => ({ ...preState, ...{ title: e.target.value } })) }} />
                                                </InputValidation> */}

                                                <InputValidation type={error.groupName.type} message={error.groupName.message}>
                                                    <Input className="value" value={selectedAddonGroup.title} style={{ width: 750 }}
                                                        onBlur={(e) => {
                                                            if (e.target.value.length <= 2) {
                                                                errorInfo.groupName.type = "error";
                                                                errorInfo.groupName.message = `${e.target.value} group name minimum 3 characters`;
                                                                setStopAddonGroup(true);
                                                            } else if (e.target.value.length > 200) {
                                                                errorInfo.groupName.type = "error";
                                                                errorInfo.groupName.message = `${e.target.value} group name maximum 200 characters`;
                                                                setStopAddonGroup(true);
                                                            } else if (errorInfo.groupName.message.length === 0) {
                                                                errorInfo.groupName.type = "";
                                                                errorInfo.groupName.message = "";
                                                                setStopAddonGroup(false);
                                                            }
                                                            setError((preState) => ({ ...preState, ...errorInfo }));
                                                        }}
                                                        onChange={(e) => {
                                                            if (isExistAddonGroup(e.target.value, selectedAddonGroup.id)) {
                                                                errorInfo.groupName.type = "error";
                                                                errorInfo.groupName.message = `${e.target.value} group name already exist`;
                                                                setStopAddonGroup(true);
                                                            } else {
                                                                errorInfo.groupName.type = "";
                                                                errorInfo.groupName.message = "";
                                                                setStopAddonGroup(false);
                                                            }
                                                            setError((preState) => ({ ...preState, ...errorInfo }));
                                                            setSelectedAddonGroup((preState) => ({ ...preState, ...{ title: e.target.value } }))
                                                        }} />
                                                </InputValidation>
                                            </div>
                                            <div style={{ border: '1px dotted #d9d9d9', margin: '10px 0px 20px 0px' }}></div>
                                            <div style={{ display: 'flex', marginBottom: 10, alignItems: "center", width: 145 }}>
                                                <div className='label'>
                                                    Mandatory
                                                </div>
                                                <Switch className="toggle-opt" style={{ marginLeft: 10 }} checked={selectedAddonGroup.mandatory} disabled={!isEdit} onChange={(e) => {
                                                    if (e) {
                                                        if (parseInt(selectedAddonGroup.minSelection) === 0) {
                                                            errorInfo.minSelection.type = "error";
                                                            errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                                                            setStopAddonGroup(true);
                                                        } else if (error.minSelection.message.length === 0) {
                                                            errorInfo.minSelection.type = "";
                                                            errorInfo.minSelection.message = "";
                                                            setStopAddonGroup(false);
                                                        }
                                                    }
                                                    setError((preState) => ({ ...preState, ...errorInfo }));
                                                    setSelectedAddonGroup((preState) => ({ ...preState, ...{ mandatory: e } }))
                                                }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ marginRight: 20, width: 145 }}>
                                                    <div className='label'>
                                                        Min Selection
                                                    </div>
                                                    <InputValidation type={error.minSelection.type} message={error.minSelection.message}>
                                                        <Input className="value" type='number' value={selectedAddonGroup.minSelection} style={{ width: 145 }} onBlur={(e) => {
                                                            let stopAddonGroup = false;
                                                            if (selectedAddonGroup.mandatory) {
                                                                if (parseInt(e.target.value) === 0) {
                                                                    errorInfo.minSelection.type = "error";
                                                                    errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                                                                    stopAddonGroup = true;
                                                                }
                                                            }
                                                            if (!stopAddonGroup) {
                                                                if (parseInt(e.target.value) > parseInt(selectedAddonGroup.maxSelection)) {
                                                                    errorInfo.minSelection.type = "error";
                                                                    errorInfo.minSelection.message = "Minimum selection should be less than maximum selection";
                                                                    stopAddonGroup = true;
                                                                } else {
                                                                    errorInfo.minSelection.type = "";
                                                                    errorInfo.minSelection.message = "";
                                                                    stopAddonGroup = false;
                                                                }
                                                            }
                                                            setStopAddonGroup(stopAddonGroup);
                                                            setError((preState) => ({ ...preState, ...errorInfo }));
                                                        }} onChange={(e) => {
                                                            if (selectedAddonGroup.mandatory) {
                                                                if (parseInt(e.target.value) === 0) {
                                                                    errorInfo.minSelection.type = "error";
                                                                    errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                                                                    setStopAddonGroup(true);
                                                                } else {
                                                                    errorInfo.minSelection.type = "";
                                                                    errorInfo.minSelection.message = "";
                                                                    setStopAddonGroup(false);
                                                                }
                                                            }
                                                            setError((preState) => ({ ...preState, ...errorInfo }));
                                                            if (parseInt(e.target.value) >= 0) {
                                                                setSelectedAddonGroup((preState) => ({ ...preState, ...{ minSelection: parseInt(e.target.value) } }));
                                                            }
                                                        }} />
                                                    </InputValidation>
                                                </div>
                                                <div style={{ width: 145 }}>
                                                    <div className='label'>
                                                        Max Selection
                                                    </div>
                                                    <InputValidation type={error.maxSelection.type} message={error.maxSelection.message}>
                                                        <Input className="value" type='number' value={selectedAddonGroup.maxSelection} onBlur={(e) => {
                                                            if (parseInt(e.target.value) < parseInt(selectedAddonGroup.minSelection)) {
                                                                errorInfo.maxSelection.type = "error";
                                                                errorInfo.maxSelection.message = "Maximum selection should be great than minimum selection";
                                                                setStopAddonGroup(true);
                                                            } else {
                                                                errorInfo.maxSelection.type = "";
                                                                errorInfo.maxSelection.message = "";
                                                                setStopAddonGroup(false);
                                                            }
                                                            setError((preState) => ({ ...preState, ...errorInfo }));
                                                        }} style={{ width: 145 }} onChange={(e) => {
                                                            if (parseInt(e.target.value) >= 0) {
                                                                setSelectedAddonGroup((preState) => ({ ...preState, ...{ maxSelection: parseInt(e.target.value) } }));
                                                            }
                                                        }} />
                                                    </InputValidation>
                                                </div>
                                            </div>
                                            <div style={{ border: '1px dotted #d9d9d9', margin: '15px 0px' }}></div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: selectedAddonGroup?.addons.length > 1 ? 420 : 426, display: 'flex', marginRight: 14 }}>
                                                    <div>Add-on Options</div>
                                                    {/* {isEdit && <div style={{
                                                        margin: "0px 10px",
                                                        border: "1px solid #000",
                                                        borderRadius: 4,
                                                        padding: "0px 10px",
                                                        cursor: "pointer"
                                                    }} onClick={() => addNewAddon()}>Add New</div>} */}
                                                </div>
                                                <div style={{ width: selectedAddonGroup?.addons.length > 1 ? 130 : 134, marginRight: 14 }}>Price</div>
                                                <div style={{ width: 100 }}>Tax</div>
                                            </div>
                                            <div>
                                                {selectedAddonGroup?.addons?.map((o, addonIndex) => <div key={`${addonIndex}_addon`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px' }}>
                                                    <div style={{ marginRight: 14 }}>
                                                        {/* <InputValidation type={error.addonName.type} message={error.addonName.message}> */}
                                                        <Input className="value" value={o.title} style={{ width: 400 }}
                                                            onBlur={(e) => {
                                                                if (e.target.value.length <= 2) {
                                                                    errorInfo.addonName.type = "error";
                                                                    errorInfo.addonName.message = `${e.target.value} addon name minimum 3 characters`;
                                                                    setStopAddonGroup(true);
                                                                } else if (e.target.value.length > 200) {
                                                                    errorInfo.addonName.type = "error";
                                                                    errorInfo.addonName.message = `${e.target.value} addon name maximum 200 characters`;
                                                                    setStopAddonGroup(true);
                                                                } else if (errorInfo.addonName.message.length === 0) {
                                                                    errorInfo.addonName.type = "";
                                                                    errorInfo.addonName.message = "";
                                                                    setStopAddonGroup(false);
                                                                }
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                            }}
                                                            onChange={(e) => {
                                                                if (isExitAddon(e.target.value, o.id)) {
                                                                    errorInfo.addonName.type = "error";
                                                                    errorInfo.addonName.message = "Addon name already exist";
                                                                    setStopAddonGroup(true);
                                                                } else {
                                                                    errorInfo.addonName.type = "";
                                                                    errorInfo.addonName.message = "";
                                                                    setStopAddonGroup(false);
                                                                }
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                                updateAddon(addonIndex, 'title', e.target.value)
                                                            }} />
                                                        {/* </InputValidation> */}
                                                    </div>
                                                    <div style={{ marginRight: 14 }}>
                                                        {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                                        <Input style={{ width: 109 }} className="value" value={o.price} onChange={(e) => {
                                                            const { value }: any = e.target;
                                                            const reg = /^-?\d*(\.\d*)?$/;
                                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                                updateAddon(addonIndex, 'price', value ? parseFloat(value) : 0)
                                                            }
                                                        }} />
                                                        {/* </InputValidation> */}
                                                    </div>
                                                    <div style={{ marginRight: 14 }}>
                                                        {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                                        <Input style={{ width: 109 }} type='number' className="value" value={o.taxRate} onChange={(e) => {
                                                            const { value }: any = e.target;
                                                            if (value >= 0 && value <= 28) {
                                                                updateAddon(addonIndex, 'taxRate', parseInt(value))
                                                            }
                                                        }} />
                                                        {/* </InputValidation> */}
                                                    </div>
                                                    <div style={{ marginRight: 14 }}>
                                                        {selectedAddonGroup?.addons.length > 1 && isEdit && <img src={DeleteIcon} style={{ cursor: "pointer", marginBottom: 2 }} alt='Delete' onClick={() => removeAddon(addonIndex)} />}
                                                    </div>
                                                </div>)}
                                                {error.addonName.type.length > 0 && <div style={{ color: "#FF0000", fontSize: 12 }}>{error.addonName.message}</div>}
                                            </div>
                                        </div>
                                        <div style={{ margin: "10px 0px", display: 'flex', alignItems: 'center' }}>
                                            {isEdit && <div className='sp_btn' onClick={() => addNewAddon()} style={{ width: 100, height: 32, marginRight: 16, cursor: !stopAddonGroup ? "pointer" : "default" }}>Add New</div>}
                                            {/* <div className='sp_btn' onClick={!stopAddonGroup && addNewAddonGroup} style={{ width: 100, marginRight: 16, cursor: !stopAddonGroup ? "pointer" : "default" }}>Submit</div>
                                            <div onClick={() => setSelectedAddonGroup(null)} style={{ cursor: 'pointer' }}>Cancel</div> */}
                                        </div>
                                        <div style={{ border: '1px solid #d9d9d9', margin: '20px 0px 20px 0px' }}></div>
                                    </div>}
                                    {/* height: windowHeight - 580, */}
                                </div>}
                                {addonGroups && addonGroups.length > 0 && <div className='group' style={{ width: 749 }}>
                                    {addonGroups.map((a, addonGroupsIndex) => <div key={`${addonGroupsIndex}_addon_group`} style={{
                                        borderRadius: 6,
                                        border: "1px solid #D9D9D9",
                                        background: "#FFF",
                                        boxShadow: "0px 4px 8px 0px rgba(217, 217, 217, 0.20)",
                                        marginBottom: 8
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 10 }}>
                                            <div onClick={() => { resetError(); setSelectedAddonGroup(JSON.parse(JSON.stringify(a))) }} style={{ cursor: !stopAddonGroup ? "pointer" : "default", display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <img src={ArrowIcon} style={{ width: 20, height: 20, marginRight: 10, transform: `rotate(${selectedAddonGroup?.title === a.title ? 0 : 266}deg)`, transition: 'rotate 1s' }} alt='Arrow' />
                                                {a.title}
                                            </div>
                                            <div>
                                                {isEdit && <img src={DeleteIcon} style={{ cursor: "pointer" }} alt='Delete' onClick={() => removeAddonGroup(a)} />}
                                            </div>
                                        </div>
                                        {selectedAddonGroup && selectedAddonGroup?.id === a.id && <div>
                                            <div style={{ height: 1, background: "#d9d9d9" }}></div>
                                            <div style={{ padding: 15 }}>
                                                <div className="label">
                                                    Group Name *
                                                </div>
                                                <div>
                                                    <InputValidation type={error.groupName.type} message={error.groupName.message}>
                                                        <Input className="value" value={selectedAddonGroup.title} style={{ width: "100%" }}
                                                            disabled={!isEdit}
                                                            onBlur={(e) => {
                                                                if (e.target.value.length <= 2) {
                                                                    errorInfo.groupName.type = "error";
                                                                    errorInfo.groupName.message = `${e.target.value} group name minimum 3 characters`;
                                                                    setStopAddonGroup(true);
                                                                } else if (e.target.value.length > 200) {
                                                                    errorInfo.groupName.type = "error";
                                                                    errorInfo.groupName.message = `${e.target.value} group name maximum 200 characters`;
                                                                    setStopAddonGroup(true);
                                                                } else if (errorInfo.groupName.message.length === 0) {
                                                                    errorInfo.groupName.type = "";
                                                                    errorInfo.groupName.message = "";
                                                                    setStopAddonGroup(false);
                                                                }
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                            }}
                                                            onChange={(e) => {
                                                                if (isExistAddonGroup(e.target.value, selectedAddonGroup.id)) {
                                                                    errorInfo.groupName.type = "error";
                                                                    errorInfo.groupName.message = `${e.target.value} group name already exist`;
                                                                    setStopAddonGroup(true);
                                                                } else {
                                                                    errorInfo.groupName.type = "";
                                                                    errorInfo.groupName.message = "";
                                                                    setStopAddonGroup(false);
                                                                }
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                                setSelectedAddonGroup((preState) => ({ ...preState, ...{ title: e.target.value } }))
                                                            }} />
                                                    </InputValidation>
                                                </div>
                                                <div style={{ border: '1px dotted #d9d9d9', margin: '10px 0px 20px 0px' }}></div>
                                                <div style={{ display: 'flex', marginBottom: 10 }}>
                                                    <div className='label'>
                                                        Mandatory
                                                    </div>
                                                    <Switch className="toggle-opt" style={{ marginLeft: 10 }} checked={selectedAddonGroup.mandatory} disabled={!isEdit} onChange={(e) => {
                                                        if (e) {
                                                            if (parseInt(selectedAddonGroup.minSelection) === 0) {
                                                                errorInfo.minSelection.type = "error";
                                                                errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                                                                setStopAddonGroup(true);
                                                            } else if (error.minSelection.message.length === 0) {
                                                                errorInfo.minSelection.type = "";
                                                                errorInfo.minSelection.message = "";
                                                                setStopAddonGroup(false);
                                                            }
                                                        }
                                                        setError((preState) => ({ ...preState, ...errorInfo }));
                                                        setSelectedAddonGroup((preState) => ({ ...preState, ...{ mandatory: e } }))
                                                    }} />
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: 20, width: 145 }}>
                                                        <div className='label'>
                                                            Min Selection
                                                        </div>
                                                        <InputValidation type={error.minSelection.type} message={error.minSelection.message}>
                                                            <Input type='number' className="value" value={selectedAddonGroup.minSelection} onBlur={(e) => {
                                                                let stopAddonGroup = false;
                                                                if (selectedAddonGroup.mandatory) {
                                                                    if (parseInt(e.target.value) === 0) {
                                                                        errorInfo.minSelection.type = "error";
                                                                        errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                                                                        stopAddonGroup = true;
                                                                    }
                                                                }
                                                                if (!stopAddonGroup) {
                                                                    if (parseInt(e.target.value) > parseInt(selectedAddonGroup.maxSelection)) {
                                                                        errorInfo.minSelection.type = "error";
                                                                        errorInfo.minSelection.message = "Minimum selection should be less than maximum selection";
                                                                        stopAddonGroup = true;
                                                                    } else {
                                                                        errorInfo.minSelection.type = "";
                                                                        errorInfo.minSelection.message = "";
                                                                        stopAddonGroup = false;
                                                                    }
                                                                }
                                                                setStopAddonGroup(stopAddonGroup);
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                            }} style={{ width: 145 }} disabled={!isEdit} onChange={(e) => {
                                                                if (selectedAddonGroup.mandatory) {
                                                                    if (parseInt(e.target.value) === 0) {
                                                                        errorInfo.minSelection.type = "error";
                                                                        errorInfo.minSelection.message = "If add-on is mandatory minimum selection should be greater than zero";
                                                                        setStopAddonGroup(true);
                                                                    } else {
                                                                        errorInfo.minSelection.type = "";
                                                                        errorInfo.minSelection.message = "";
                                                                        setStopAddonGroup(false);
                                                                    }
                                                                }
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                                if (parseInt(e.target.value) >= 0) {
                                                                    setSelectedAddonGroup((preState) => ({ ...preState, ...{ minSelection: parseInt(e.target.value) } }))
                                                                }
                                                            }} />
                                                        </InputValidation>
                                                    </div>
                                                    <div style={{ width: 145 }}>
                                                        <div className='label'>
                                                            Max Selection
                                                        </div>
                                                        <InputValidation type={error.maxSelection.type} message={error.maxSelection.message}>
                                                            <Input className="value" type='number' value={selectedAddonGroup.maxSelection} style={{ width: 145 }} disabled={!isEdit} onBlur={(e) => {
                                                                if (parseInt(e.target.value) < parseInt(selectedAddonGroup.minSelection)) {
                                                                    errorInfo.maxSelection.type = "error";
                                                                    errorInfo.maxSelection.message = "Maximum selection should be great than minimum selection";
                                                                    setStopAddonGroup(true);
                                                                } else {
                                                                    errorInfo.maxSelection.type = "";
                                                                    errorInfo.maxSelection.message = "";
                                                                    setStopAddonGroup(false);
                                                                }
                                                                setError((preState) => ({ ...preState, ...errorInfo }));
                                                            }} onChange={(e) => {
                                                                if (parseInt(e.target.value) >= 0) {
                                                                    setSelectedAddonGroup((preState) => ({ ...preState, ...{ maxSelection: parseInt(e.target.value) } }))
                                                                }
                                                            }} />
                                                        </InputValidation>
                                                    </div>
                                                </div>
                                                <div style={{ border: '1px dotted #d9d9d9', margin: '15px 0px' }}></div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ width: selectedAddonGroup?.addons.length > 1 ? 422 : 416, display: 'flex', marginRight: 14 }}>
                                                        <div>Add-on Options</div>
                                                        {isEdit && <div style={{
                                                            margin: "0px 10px",
                                                            border: "1px solid #000",
                                                            borderRadius: 4,
                                                            padding: "0px 10px",
                                                            cursor: "pointer"
                                                        }} onClick={() => addNewAddon()}>Add New</div>}
                                                    </div>
                                                    <div style={{ width: selectedAddonGroup?.addons.length > 1 ? 118 : 120, marginRight: 14 }}>Price</div>
                                                    <div style={{ width: 100 }}>Tax</div>
                                                </div>
                                                <div>
                                                    {selectedAddonGroup?.addons?.map((o, addonIndex) => <div key={`${addonIndex}_addon`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px' }}>
                                                        <div style={{ marginRight: 14 }}>
                                                            {/* <InputValidation type={error.addonName.type} message={error.addonName.message}> */}
                                                            <Input disabled={!isEdit} className="value" value={o.title} style={{ width: 400 }}
                                                                onBlur={(e) => {
                                                                    if (e.target.value.length <= 2) {
                                                                        errorInfo.addonName.type = "error";
                                                                        errorInfo.addonName.message = `${e.target.value} addon name minimum 3 characters`;
                                                                        setStopAddonGroup(true);
                                                                    } else if (e.target.value.length > 200) {
                                                                        errorInfo.addonName.type = "error";
                                                                        errorInfo.addonName.message = `${e.target.value} addon name maximum 200 characters`;
                                                                        setStopAddonGroup(true);
                                                                    } else if (errorInfo.groupName.message.length === 0) {
                                                                        errorInfo.addonName.type = "";
                                                                        errorInfo.addonName.message = "";
                                                                        setStopAddonGroup(false);
                                                                    }
                                                                    setError((preState) => ({ ...preState, ...errorInfo }));
                                                                }}
                                                                onChange={(e) => {
                                                                    if (isExitAddon(e.target.value, o.id)) {
                                                                        errorInfo.addonName.type = "error";
                                                                        errorInfo.addonName.message = `${e.target.value} addon name already exist`;
                                                                        setStopAddonGroup(true);
                                                                    } else {
                                                                        errorInfo.addonName.type = "";
                                                                        errorInfo.addonName.message = "";
                                                                        setStopAddonGroup(false);
                                                                    }
                                                                    setError((preState) => ({ ...preState, ...errorInfo }));
                                                                    updateAddon(addonIndex, 'title', e.target.value)
                                                                }} />
                                                            {/* </InputValidation> */}
                                                        </div>
                                                        <div style={{ marginRight: 14 }}>
                                                            {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                                            <Input style={{ width: 109 }} className="value" value={o.price} disabled={!isEdit} onChange={(e) => {
                                                                const { value }: any = e.target;
                                                                const reg = /^-?\d*(\.\d*)?$/;
                                                                if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                                    updateAddon(addonIndex, 'price', value ? parseFloat(value) : 0)
                                                                }
                                                            }} />
                                                            {/* </InputValidation> */}
                                                        </div>
                                                        <div style={{ marginRight: 14 }}>
                                                            <InputValidation type={error.title.type} message={error.title.message}>
                                                                <Input style={{ width: 109 }} className="value" value={o.taxRate} disabled={!isEdit} onChange={(e) => {
                                                                    const { value }: any = e.target;
                                                                    if (value >= 0 && value <= 28) {
                                                                        updateAddon(addonIndex, 'taxRate', parseInt(value))
                                                                    }
                                                                }} />
                                                            </InputValidation>
                                                        </div>
                                                        <div style={{ marginRight: 14 }}>
                                                            {selectedAddonGroup?.addons.length > 1 && isEdit && <img src={DeleteIcon} style={{ cursor: "pointer", marginBottom: 2 }} alt='Delete' onClick={() => removeAddon(addonIndex)} />}
                                                        </div>
                                                    </div>)}
                                                    {error.addonName.type.length > 0 && <div style={{ color: "#FF0000", fontSize: 12 }}>{error.addonName.message}</div>}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>)}
                                </div>}
                                {selectedAddonGroup === null && addonGroups && addonGroups.length === 0 && <div style={{
                                    borderRadius: 4,
                                    border: "1px dashed #D9D9D9",
                                    background: "#FFFFFF",
                                    height: 374,
                                    margin: "15px",
                                    width: '96%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }} >
                                    <img src={NoteOutlineSvg} alt='Note' />
                                    <div style={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        color: "#121212",
                                        margin: "25px 0px 8px 0px"
                                    }}>Add on Groups</div>
                                    <div style={{
                                        fontWeight: 500,
                                        fontSize: 14,
                                        color: "#797979",
                                        marginBottom: 31
                                    }}>You haven't created any add-on groups yet.</div>
                                    {isEdit && <div style={{
                                        padding: "10px 18px",
                                        background: "#121212",
                                        borderRadius: 4,
                                        fontWeight: 600,
                                        fontSize: 13,
                                        color: "#FFFFFF",
                                        cursor: "pointer",
                                    }} onClick={onCreateAddonGroup} >+ Add-on Group</div>}
                                </div>}
                            </div>

                            {/* <div className="group">
                                <div>
                                    <div className="label" >Attach to a outlet</div>
                                </div>
                                <div>
                                    <Select mode='multiple' defaultValue={productLabels} value={productLabels} disabled={!isEdit} onChange={(newValue: string[]) => { setProductLabels(newValue) }} maxTagCount='responsive' placeholder='Select one or more outlets' >
                                        <Option value="topPick">Outlet 1</Option>
                                        <Option value="bestSeller">Outlet 2</Option>
                                        <Option value="whatsNew">Outlet 3</Option>
                                    </Select>
                                </div>
                            </div> */}

                        </div>
                        <div style={{
                            width: 395,
                            display: selectedTabMenu === "product_specification" ? 'block' : 'none',
                        }}>
                            {((variants === null) || (variants && variants.length <= 1)) ? <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#FFF',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 12,
                                border: "1px solid #D9D9D9", borderRadius: 8, boxShadow: "0px 4px 16px 0px #C6C6C61F"
                            }}>
                                <div className="group" style={{ width: 365 }}>
                                    <div>
                                        <div className="label" >Price</div>
                                    </div>
                                    <div>
                                        <Input type="number" disabled={!isEdit} name="salePrice" min="1" value={price} placeholder="₹0"
                                            onChange={(e) => setPrice(parseFloat(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="group" style={{ width: 365 }}>
                                    <div>
                                        <div className="label" >Sale Price</div>
                                    </div>
                                    <div>
                                        <Input type="number" disabled={!isEdit} name="salePrice" min="1" value={salePrice} placeholder="₹0"
                                            onChange={(e) => setSalePrice(parseFloat(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="group" style={{ width: 365 }}>
                                    <div>
                                        <div className="label" >Product SKU</div>
                                    </div>
                                    <div>
                                        <InputValidation type={error.sku.type} message={error.sku.message}>
                                            <Input disabled={!isEdit} className="value" value={sku} onChange={(e) =>
                                                setSku(e.target.value.replace(/ /g, "_").toLocaleUpperCase())
                                            } />
                                        </InputValidation>
                                    </div>
                                </div>
                            </div> : <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#FFF',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 12,
                                border: "1px solid #D9D9D9", borderRadius: 8, boxShadow: "0px 4px 16px 0px #C6C6C61F"
                            }}>
                                <div className="group" style={{ width: 365 }}>
                                    <div>
                                        <div onClick={() => setSelectedTabMenu('variants')} className="label" style={{ fontWeight: 800, cursor: 'pointer', margin: 0 }}>See {variants.length} Variants &gt;</div>
                                    </div>
                                </div>
                            </div>}
                            <div style={{
                                background: '#FFF',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 12,
                                border: "1px solid #D9D9D9", borderRadius: 8, boxShadow: "0px 4px 16px 0px #C6C6C61F"
                            }}>
                                <div className="group" style={{ width: 365 }}>
                                    <div>
                                        <div className="label" >Max Items Per Order</div>
                                    </div>
                                    <div>
                                        <InputValidation type={error.maxItemsPerOrder.type} message={error.maxItemsPerOrder.message}>
                                            <Input
                                                disabled={!isEdit}
                                                className="value"
                                                name="maxItemsPerOrder"
                                                pattern="[0-9]*"
                                                value={maxItemsPerOrder}
                                                placeholder="Enter the max items per order"
                                                onChange={(e) => {
                                                    const { value }: any = e.target;
                                                    const reg = /^-?\d*?$/;
                                                    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                        setMaxItemsPerOrder(value);
                                                    }
                                                }}
                                            />
                                        </InputValidation>
                                    </div>
                                </div>
                                <div className="group" style={{ width: 365 }}>
                                    <div className="label" >
                                        Tax (GST %)
                                    </div>
                                    <div>
                                        {/* <InputValidation type={error.sku.type} message={error.sku.message}> */}
                                        <Input disabled={!isEdit} type="number" className="value" value={taxRate} onChange={(e) => {
                                            const { value }: any = e.target;
                                            if (value >= 0 && value <= 28) {
                                                setTaxRate(parseInt(value))
                                            }
                                        }} />
                                        {/* </InputValidation> */}
                                    </div>
                                </div>
                                <div className="group" style={{ width: 365 }}>
                                    <div className="label" >HSN Code</div>
                                    <div>
                                        <InputValidation type={error.hsnCode.type} message={error.hsnCode.message}>
                                            <Input type='number' className="value" value={hsnCode} disabled={!isEdit} onChange={(e: any) => {
                                                setHSNCode(e.target.value);
                                            }} />
                                        </InputValidation>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: selectedTabMenu === "variants" ? 'block' : 'none' }}>
                            {<VariantsCmp
                                isEdit={isEdit}
                                setVariants={setVariants}
                                variants={variants}
                                options={options}
                                selectedProduct={props.selectedProduct}
                                setOptions={setOptions} />}
                        </div>
                    </div>
                    {/* <div className="action-bar">
                    <div>
                        <div></div>
                        <div style={{ paddingRight: "15px" }}>
                            <button onClick={() => props.onClose()} className="sp_btn cancel">Cancel</button>
                        </div>
                        <div></div>
                        <div style={{ paddingLeft: "15px" }}>
                            {isEdit && <button onClick={onSubmit} className="sp_btn save">{props.selectedProduct ? "Update Product" : "Create Product"}</button>}
                            {!isEdit && <button onClick={() => setIsEdit(true)} className="sp_btn edit">Edit</button>}
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        </Drawer >
    )
}

function mapStateToProps(state: any) {
    // console.log("Product View mapStateToProps: ", state);
    return {
        common: state.common,
        ui: state.ui,
        product: state.product
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCategories,
        getOutlets,
        createProduct,
        uploadFile,
        updateProduct,
        deleteFile,
        onUploadMedia
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Save);
