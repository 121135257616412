import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import InstagramSvg from "../../../../../assets/images/settings/instagram.svg";
import InstagramWhiteSvg from "../../../../../assets/images/settings/instagram-white.svg";
import InstagramBotSvg from "../../../../../assets/images/instagram-bot.svg";
import { updateChannelSettings, getChannelSettings } from "../../../../../actions/commonAction";
import { useDispatch } from "react-redux";
import PopupConfirm from "../../../common/PopupConfirm";
import Loading from "../../../common/Loading";
import MarkdownViewer from "../../../common/MarkdownViewer";

const Instagram = () => {
    const dispatch = useDispatch();
    const WindowObj: any = window;
    const [enabled, setEnabled] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [pageName, setPageName] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        const data = await getChannelSettings('instagram')(dispatch);
        if (data) {
            setEnabled(data.enabled);
            setPageId(data.pageId || null);
            setPageName(data.pageName || null);
        } else {
            setEnabled(null);
            setPageId(null);
            setPageName(null);
        }
    }

    useEffect(() => {
        fetchData();
        //eslint-disable-next-line
    }, []);

    const onSave = async (authCode) => {
        if (authCode) {
            await updateChannelSettings('instagram', { authCode, enabled: true })(dispatch);
            fetchData();
        }
    }

    const onDisconnect = async () => {
        const title = `Disconnect Instagram Page`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Are you sure you want to disconnect<span style={{ fontWeight: "bold", margin: "0px 4px" }}>{pageName}</span>from Shopletzy?</span></div> },
            yes: {
                label: "Disconnect",
                callback: async () => {
                    setIsLoading(true);
                    await updateChannelSettings('instagram', { authCode: '', enabled: false })(dispatch);
                    fetchData();
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    useEffect(() => {
        const handleMessage = (event) => {
            // event.origin !== "https://expected-origin.com"
            const { authCode, sender } = event.data;
            if (sender !== "instagram_oauth_handler") {
                console.warn("Unauthorized origin:", event.origin);
                return;
            }
            if (authCode) {
                onSave(authCode);
            }
        };
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
        //eslint-disable-next-line
    }, []);

    const login = () => {
        let left = window.innerWidth / 2;
        let top = window.innerHeight / 2;
        WindowObj.open(`${window.origin}/instagram_oauth_handler.html`, '_blank', `top=${top},left=${left},height=800,width=600,modal=yes,alwaysRaised=yes`);
    }

    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                </div>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div className="header" style={{ display: 'flex', justifyContent: 'space-between', height: 40 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="Whatsapp" src={InstagramSvg} style={{ marginRight: 10 }} />Instagram
                            </div>
                        </div>
                        <div className="section" style={{ display: 'flex' }}>
                            <div style={{ width: '36vw' }}>
                                <div className="title">Enable Instagram</div>
                            </div>
                            <div style={{
                                width: '100%'
                            }}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={(pageId !== null || pageName !== null) ? true : false}
                                    onClick={(val) => {
                                        setEnabled(val)
                                    }}
                                    checked={enabled}
                                />
                            </div>
                        </div>
                        {enabled && <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex'
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title" style={{ marginBottom: 10 }}>Instagram Messenger</div>
                                        <div style={{ color: "#697b95", width: '72%' }} >To automate replies, allow us to manage
                                            message for your page.</div>
                                        <div style={{ marginTop: 28, color: '#404040', display: 'flex', gap: 10, alignItems: 'center' }}>
                                            {(!pageId && !pageName) && <button onClick={login} style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                padding: "10px 20px",
                                                gap: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                color: '#fff',
                                                border: 'none',
                                                borderRadius: 5,
                                                background: 'linear-gradient(90deg, #188FFC -10.51%, #FD16B7 67.5%, #FF8B1E 116.62%, #F80C0C 229.67%)'
                                            }}>
                                                <img src={InstagramWhiteSvg} alt="Instagram" />
                                                Sign in with Instagram
                                            </button>}
                                            {pageName}
                                            {(pageId || pageName) && <button onClick={onDisconnect} style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                padding: "10px 20px",
                                                gap: 10,
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                color: '#c70039',
                                                border: 'none',
                                                borderRadius: 5,
                                                background: '#fff'
                                            }}>
                                                Disconnect
                                            </button>}
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <div>
                                            <img src={InstagramBotSvg} alt="Instagram Bot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div
                style={{
                    height: WindowObj.innerHeight - 72,
                    overflowY: 'auto',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <MarkdownViewer fileName={'SettingUpInstagramMessengerChatbot'} />
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default Instagram;