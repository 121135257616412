import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Input, InputNumber, Select } from "antd";
import InputValidation from '../../../common/InputValidation';
import React, { useState } from "react";
import "../../../../../assets/sass/dsk/holidays.sass";
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { addSitemapUrl } from '../../../../../actions/sitemapActions';
import { useDispatch } from "react-redux";
import Loading from '../../../common/Loading';

dayjs.extend(utc);
const emptyError = {
    url: {
        message: "",
        type: ""
    },
    frequency: {
        message: "",
        type: ""
    },
    priority: {
        message: "",
        type: ""
    }
};

const Frequencies = {
    "always": "Always",
    "hourly": "Hourly",
    "daily": "Daily",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "yearly": "Yearly",
    "never": "Never"
}

const { Option } = Select;

const Add = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [priorityLimit, setPriorityLimit] = useState(null);
    const [error, setError] = useState(JSON.parse(JSON.stringify(emptyError)));

    const onSubmit = async () => {
        let errorExists = false;
        let payload = {
            "url": url,
            "lastModified": dayjs(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            "changeFrequency": frequency,
            "priority": priorityLimit
        }
        if (!isNotNullOrUndefinedString(payload.url)) {
            error.url.message = "Missing url";
            error.url.type = "error";
            errorExists = true;
        } else {
            error.url.message = "";
            error.url.type = "";
        }
        if (!isNotNullOrUndefinedString(payload.changeFrequency)) {
            error.frequency.message = "Missing frequency";
            error.frequency.type = "error";
            errorExists = true;
        } else {
            error.frequency.message = "";
            error.frequency.type = "";
        }
        if (payload.priority === null && !isNotNullOrUndefinedString(payload.priority)) {
            error.priority.message = "Missing priority";
            error.priority.type = "error";
            errorExists = true;
        } else {
            error.priority.message = "";
            error.priority.type = "";
        }
        setError((prevState) => ({ ...prevState, ...error }));
        if (!errorExists) {
            setIsLoading(true);
            const resp = await addSitemapUrl(payload)(dispatch);
            if (resp?.sitemapUrlId) {
                onClose(true);
            }
            setIsLoading(false);
        }
    }

    const onClose = (isSave = false) => {
        setUrl(null);
        setFrequency(null);
        setPriorityLimit(null);
        setError(JSON.parse(JSON.stringify(emptyError)));
        props.onClose(isSave);
    }

    return <Drawer
        width={470}
        maskClosable={false}
        closable={false}
        onClose={() => onClose()}
        className="add_holiday"
        open={props.isVisible}
        footer={<div className="footer">
            <div className="sp_btn" onClick={() => onClose()} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
            <div className="sp_btn" onClick={onSubmit} style={{ width: 120, fontSize: 14 }}>Add</div>
        </div>}>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={() => onClose()}></ArrowLeftOutlined>
            <div className="title">Add Url</div>
        </div>
        <div className="content">
            <div className="group">
                <div className="label">URL *</div>
                <InputValidation type={error?.url?.type} message={error?.url?.message}>
                    <Input type="text" name="name" defaultValue={url} placeholder="Enter Url"
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </InputValidation>
            </div>
            <div className="group">
                <div className="label">Change Frequency *</div>
                <InputValidation type={error?.frequency?.type} message={error?.frequency?.message}>
                    <Select
                        style={{
                            border: '1px solid #D9D9D9'
                        }}
                        showSearch
                        filterOption={(inputValue, option) =>
                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        onChange={(value) => {
                            setFrequency(value)
                        }}  >
                        {Object.keys(Frequencies).map((f: any) => {
                            return <Option key={f} value={f} style={{ textTransform: 'capitalize' }}>{Frequencies[f]}</Option>
                        })}
                    </Select>
                </InputValidation>
            </div>
            <div className="group">
                <div className="label">Priority Limit *</div>
                <InputValidation type={error?.priority?.type} message={error?.priority?.message}>
                    <InputNumber style={{ width: '100%' }} min={0} max={1} name="priorityLimit" defaultValue={priorityLimit} placeholder="eg: 0 - 1"
                        onChange={(val) => setPriorityLimit(val ?? 0)}
                        step={0.1}
                    />
                </InputValidation>
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </Drawer >
}

export default Add;