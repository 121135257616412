const apisUrl = {
    baseUrl: window.baseURL.replace(/\/$/, ''),//'http://192.168.1.11:3978',
    shopWebURL: window.shopWebURL,
    baseImageUrl: 'https://storage.googleapis.com/',
    shopWebhookUrl: 'https://shop-api.{env}.shopletzy.com/bots',
    authUrl: '/v1/oauth',
    authOTPUrl: '/v1/oauth?signup=true',
    emailSignupUrl: '/v1/signup',
    uploadProductImageUrl: '/v1/stores/{sId}/products/{productId}/uploadImage',
    deleteProductImageUrl: '/v1/stores/{sId}/products/{productId}/deleteImage',
    categoriesUrl: '/v1/stores/{sId}/categories',
    uploadCategoryImageUrl: '/v1/stores/{sId}/categories/{categoryId}/uploadImage',
    deleteCategoryImageUrl: '/v1/stores/{sId}/categories/{categoryId}/deleteImage',
    deleteCategoryUrl: '/v1/stores/{sId}/categories/{categoryId}',
    productsUrl: '/v1/stores/{sId}/products',
    productsExportUrl: '/v1/stores/{sId}/products/export',
    saveProductUrl: '/v1/stores/{sId}/products/{productId}',
    addProductToOutletUrl: '/v1/stores/{sId}/products/{productId}/addToOutlet',
    updateProductToOutletUrl: '/v1/stores/{sId}/products/{productId}/updateOutlet',
    outletsUrl: '/v1/stores/{sId}/outlets',
    storesUrl: '/v1/stores',
    storeDetailUrl: '/v1/stores/{sId}',
    updateOutletUrl: '/v1/stores/{sId}/outlets/{ouId}',
    employeesUrl: '/v1/stores/{sId}/employees',
    updateEmployeeUrl: '/v1/stores/{sId}/employees/{employeeId}',
    domainLookupUrl: '/v1/stores/{sId}/lookup?domain={domain}',
    storeAvailabilityUrl: '/v1/checkAvailability/storeName?storeName={storeName}',
    signinUrl: '/v1/stores/{sId}/auth',//'/authenticate',
    signupUrl: '/v1/stores/{sId}/signup',
    getIndustryTypesUrl: '/v1/configurations/industryTypes',
    sendOTPUrl: '/v1/verification/mobile/sendOTP',
    verifyOTPUrl: '/v1/verification/mobile/verifyOTP',
    createMerchantUrl: '/v1/stores/{sId}/accountSetup/merchantDetails',
    searchAddressUrl: '/locations/v1/autocomplete?input={data}',
    getAddressInfoUrl: '/locations/v1/placeDetails?placeId={placeId}',
    ordersUrl: '/v1/stores/{sId}/orders',
    orderDetailUrl: '/v1/stores/{sId}/orders/{orderId}',
    orderStatusUrl: '/v1/stores/{sId}/orders/{orderId}/{status}',
    allOrdersUrl: '/v1/stores/{sId}/orders',
    paymentFailureOrdersUrl: '/v1/stores/{sId}/paymentFailureOrders',
    pendingOrdersUrl: '/v1/stores/{sId}/pendingOrders',
    assignedOrdersUrl: '/v1/stores/{sId}/assignedOrders?ouId={ouId}&startDate={startDate}&endDate={endDate}',
    incompleteOrdersUrl: '/v1/stores/{sId}/incompleteOrders',
    getDeliveryexecutivesUrl: '/v1/stores/{sId}/outlets/{ouId}/deliveryExecutives',
    updateAllOrdersStatusUrl: '/v1/stores/{sId}/orders/status',
    payoutAccountsUrl: '/v1/stores/{sId}/payoutAccounts',
    setPrimaryAccountsUrl: '/v1/stores/{sId}/payoutAccounts/{accountId}/setPrimary',
    assignDeliveryUrl: '/v1/stores/{sId}/orders/{orderId}/assignDelivery',
    faqUrl: '/v1/stores/{sId}/faqs',
    faqByIdUrl: 'v1/stores/{sId}/faqs/{faqId}',
    publishFAQUrl: '/v1/stores/{sId}/faqs/{faqId}/publish',
    unpublishFAQUrl: '/v1/stores/{sId}/faqs/{faqId}/unpublish',
    addFAQQuestionUrl: '/v1/stores/{sId}/faqs/{faqId}/questions',
    updateFAQQuestionUrl: '/v1/stores/{sId}/faqs/{faqId}/questions/{questionId}',
    publishFAQQuestionUrl: '/v1/stores/{sId}/faqs/{faqId}/questions/{questionId}/publish',
    unpublishFAQQuestionUrl: '/v1/stores/{sId}/faqs/{faqId}/questions/{questionId}/unpublish',
    mediaForUseUrl: '/v1/stores/{sId}/media?forUse={forUse}',
    mediaUrl: '/v1/stores/{sId}/media',
    uploadMediaUrl: '/v1/stores/{sId}/media/upload',
    deleteMediaUrl: '/v1/stores/{sId}/media/delete',
    createNewStoreUrl: '/v1/newStore',
    deliveryAreaUrl: '/v1/stores/{sId}/outlets/{ouId}/deliveryArea',
    userUrl: '/v1/stores/{sId}/users',
    orderShipUrl: '/v1/stores/{sId}/orders/{orderId}/ship',
    siteSettingsUrl: '/v1/stores/{sId}/settings',
    siteStatusUrl: '/v1/stores/{sId}/siteStatus',
    businessHoursUrl: '/v1/stores/{sId}/businessHours',
    getCustomersUrl: '/v1/stores/{sId}/customers',
    exportCustomerUrl: '/v1/stores/{sId}/customers/export',
    searchCustomerUrl: '/v1/stores/{sId}/searchCustomers',
    grantAccessUrl: '/v1/stores/{sId}/grantStoreAccess',
    removeAccessUrl: '/v1/stores/{sId}/removeUser',
    searchUserUrl: '/v1/searchUsers?q={q}',
    rolesUrl: '/v1/stores/{sId}/roles',
    promotionsUrl: '/v1/stores/{sId}/promotions',
    updatePromotionsUrl: '/v1/stores/{sId}/promotions/{promotionId}',
    deliverySlotsUrl: '/v1/stores/{sId}/deliverySlots',
    updateDeliverySlotUrl: '/v1/stores/{sId}/deliverySlots/{slotId}',
    updateOutletDeliverySlotUrl: '/v1/stores/{sId}/outlets/{ouId}/deliverySlots',
    updateProductDeliverySlotUrl: '/v1/stores/{sId}/outlets/{productId}/deliverySlots',
    customerOrdersUrl: '/v1/stores/{sId}/customers/{cusId}/orders?lastPageItemTime={lastPageItemTime}',
    updateOutletSlotUrl: '/v1/stores/{sId}/outlets/{ouId}/deliverySlots',
    outletSlotUrl: '/v1/stores/{sId}/deliverySlots?ouId={ouId}',
    orderPerDayUrl: '/v1/stores/{sId}/reports',
    removeProductFromOutletUrl: '/v1/stores/{sId}/products/{productId}/removeFromOutlet',
    appBuildsUrl: '/v1/stores/{sId}/shopApps/{platform}/builds',
    uploadAppSettingsUrl: '/v1/stores/{sId}/settings/upload',
    appSettingsUrl: '/v1/stores/{sId}/shopApps/{platform}/settings',
    getDomainsUrl: '/v1/stores/{sId}/domains',
    addDomainUrl: '/v1/stores/{sId}/addDomain',
    removeDomainUrl: '/v1/stores/{sId}/removeDomain',
    getIncompleteOrderDatesUrl: '/v1/stores/{sId}/slotsForIncompleteOrders',
    getProcurementUrl: '/v1/stores/{sId}/reports/procurement',
    getSubscriptionPlansUrl: '/v1/subscriptionPlans',
    paymentSuccessUrl: '/v1/subscriptionPaymentSuccess',
    paymentFailureUrl: '/v1/subscriptionPaymentFailure',
    cancelSubscriptionUrl: '/v1/stores/{sId}/subscriptions/{subscriptionId}/cancel',
    purchaseServiceUrl: '/v1/stores/{sId}/subscriptions/{subscriptionId}/purchaseService',
    chargesUrl: '/v1/stores/{sId}/additionalCharges',
    saveChargesUrl: '/v1/stores/{sId}/additionalCharges/{cId}',
    getOrderInvoiceUrl: '/v1/stores/{sId}/orders/{orderId}/invoice',
    updateThemeConfiguration: "/v1/stores/{sId}/themeConfiguration",
    postsUrl: "/v1/stores/{sId}/posts",
    postsOrPagesUrl: "/v1/stores/{sId}/posts?type={type}",
    postByIdUrl: "/v1/stores/{sId}/posts/{postId}",
    pageByIdUrl: "/v1/stores/{sId}/pages/{pageId}",
    blogsUrl: "/v1/stores/{sId}/blogs",
    blogByIdUrl: "/v1/stores/{sId}/blogs/{blogId}",
    pagesRedirectUrl: "/v1/stores/{sId}/pageRedirects",
    pageRedirectByIdUrl: "/v1/stores/{sId}/pageRedirects/{pageRedirectId}",
    addOnGroupsUrl: "/v1/stores/{sId}/products/{productId}/addOnGroups",
    saveAddOnGroupUrl: "/v1/stores/{sId}/products/{productId}/addOnGroups/{addOnGroupId}",
    otpLoginUrl: "/v1/otpLogin",
    bulkImportUrl: "/v1/stores/{sId}/products/bulkImport",
    getNotificationUrl: "/v1/stores/{sId}/pushNotifications",
    updateNotificationUrl: "/v1/stores/{sId}/pushNotifications/{nId}",
    getAllThemesUrl: '/v1/stores/{sId}/themes',
    installThemeUrl: '/v1/themes/{themeId}/install',
    getInstalledThemesUrl: "/v1/stores/{sId}/themes/installed",
    themesConfigUrl: "/v1/stores/{sId}/themes/{themeId}/configuration",
    themeApplyPresetUrl: "/v1/stores/{sId}/themes/{themeId}/applyPreset",
    activeThemeUrl: "/v1/stores/{sId}/themes/{themeId}/activate",
    collectionsUrl: "/v1/stores/{sId}/collections",
    collectionByIdUrl: "/v1/stores/{sId}/collections/{collectionId}",
    generateRuleDescriptionUrl: "/v1/stores/{sId}/promotions/generateRuleDescription",
    getNavigationMenusUrl: "/v1/stores/{sId}/navigation/menus",
    getNavigationMenuByIdUrl: "/v1/stores/{sId}/navigation/menus/{menuId}",
    getMenuItemsUrl: "/v1/stores/{sId}/navigation/menus/{menuId}/items",
    updateOrderMenuItemsUrl: "/v1/stores/{sId}/navigation/menus/{menuId}/items/order",
    createMenuUrl: "/v1/stores/{sId}/navigation/menus",
    createMenuItemUrl: "/v1/stores/{sId}/navigation/menus/{menuId}/items",
    navigationMenuItemUrl: "/v1/stores/{sId}/navigation/menus/{menuId}/items/{itemId}",
    navigationMenuUrl: "/v1/stores/{sId}/navigation/menus/{menuId}",
    createSubscriptionUrl: "/v1/createSubscription",
    getSubscriptionsUrl: "/v1/stores/{sId}/subscriptions",
    billingAccountUrl: "/v1/stores/{sId}/billingAccount",
    getInvoiceUrl: '/v1/stores/{sId}/invoices',
    getInvoiceByServiceIdUrl: '/v1/stores/{sId}/invoices?serviceId={serviceId}',
    getInvoiceByIdUrl: 'v1/stores/{sId}/invoices/{invoiceId}',
    downloadInvoiceUrl: '/v1/stores/{sId}/invoices/{invoiceId}/download',
    billMakePaymentUrl: '/v1/stores/{sId}/invoices/makePayment',
    invoiceMakePaymentSuccessUrl: '/v1/stores/{sId}/invoices/paymentSuccess',
    invoiceMakePaymentFailureUrl: '/v1/stores/{sId}/invoices/paymentFailure',
    channelSettingsUrl: '/v1/stores/{sId}/channels/{channel}/settings',
    botSettingsUrl: '/v1/stores/{sId}/botSettings',
    lexiUrl: '/v1/stores/{sId}/lexi/generate/{useFor}',
    getProductOptionsUrl: '/v1/stores/{sId}/searchProductOptions',
    messengerConfigId: "524783793272179",
    whatsappConfigId: "521170830749138",
    instagramConfigId: "2022798084824594",
    reactivateSubscriptionUrl: '/v1/stores/{sId}/subscriptions/{subscriptionId}/reactivate',
    getListedProductsUrl: '/v1/stores/{sId}/outlets/{ouId}/inventory',
    productByIdOutletUrl: '/v1/stores/{sId}/products/{productId}?ouId={ouId}',
    updateInventoryUrl: '/v1/stores/{sId}/outlets/{ouId}/updateInventory',
    sitemapUrl: '/v1/stores/{sId}/sitemapUrls',
    removeSitemapUrl: '/v1/stores/{sId}/sitemapUrls/{sitemapUrlId}',
    webhookEventsUrl: '/v1/stores/{sId}/webhookEvents',
    webhooksUrl: '/v1/stores/{sId}/webhooks',
    webhookByIdUrl: '/v1/stores/{sId}/webhooks/{webhookId}'
}

export default apisUrl;