import React, { useEffect, useState } from "react";
import Save from "./Save";
import "../../../../../assets/sass/dsk/webhook.sass";
import SearchIcn from "../../../../../assets/images/search.svg"
import { getListWebhooks, deleteWebhook, getWebhookById } from "../../../../../actions/webhookAction";
import DeleteSvg from "../../../../../assets/images/settings/trash.svg";
import EditSvg from "../../../../../assets/images/settings/edit.svg";
import { useDispatch } from "react-redux";
import Loading from '../../../common/Loading';
import { Input, Row, Col } from "antd";
import PopupConfirm from '../../../common/PopupConfirm';

const Webhook = () => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [webhooks, setWebhooks] = useState(null);
    const [webhook, setWebhook] = useState(null);
    const [filterWebhooks, setFilterWebhooks] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const onLoad = async () => {
        setIsLoading(true);
        const resp = await getListWebhooks()(dispatch);
        if (resp?.webhooks) {
            setWebhooks(resp.webhooks)
        } else {
            setWebhooks([]);
        }
        setIsLoading(false);
    }
    const onDrawerClose = (isLoad) => {
        setOpenDrawer(false);
        setWebhook(null);
        if (isLoad) {
            onLoad();
        }
    }
    const onFilter = () => {
        let filterResults = [];
        let isAdd = false;
        for (let s of webhooks) {
            if (!search || (search.trim().length === 0)) {
                filterResults.push(s);
            } else {
                if (s.url.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    isAdd = true;
                }
                if (s.status.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    isAdd = true;
                }
            }
            if (isAdd) {
                filterResults.push(s);
            }
        }
        setFilterWebhooks(filterResults);
    }
    useEffect(() => {
        if (webhooks) {
            onFilter();
        }
        //eslint-disable-next-line
    }, [search]);
    useEffect(() => {
        if (webhooks) {
            onFilter();
        }
        //eslint-disable-next-line
    }, [webhooks])

    const onDelete = (item) => {
        console.log(item)
        const title = `Webhook remove url`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold", textTransform: "uppercase", marginRight: 4 }}>{item.url}</span>webhook</span></div > },
            yes: {
                label: "Remove",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await deleteWebhook(item.id)(dispatch);
                    if (resp) {
                        onLoad();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    useEffect(() => {
        onLoad();
        //eslint-disable-next-line
    }, []);
    const getWebhookDetail = async (item) => {
        setIsLoading(true);
        const resp = await getWebhookById(item.id)(dispatch);
        setIsLoading(false);
        if (resp) {
            setWebhook(resp);
            setOpenDrawer(true);
        }
    }
    return <div className="sitemap">
        {webhooks !== null && webhooks.length > 0 && <div className="action-bar">
            <Input className="filter" name="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" suffix={<img alt='Search Icon' src={SearchIcn} />} />
            <button className="sp_btn" style={{ width: "auto" }} onClick={() => { setOpenDrawer(true) }} >+ Add Url</button>
        </div>}
        {webhooks && webhooks.length > 0 && <div className="sm-container">
            <Row className="header" style={{ marginLeft: 30 }}>
                <Col span={10}>
                    URL
                </Col>
                <Col span={5}>
                    Status
                </Col>
                <Col span={5} style={{ textAlign: 'center' }}>
                    Events
                </Col>
                <Col span={4}>
                </Col>
            </Row>
            <Row className="list">
                <Col span={24}>
                    {filterWebhooks && filterWebhooks.map((record, index) =>
                        <Row className="item" key={record.id}>
                            <Col span={10}>
                                {record.url}
                            </Col>
                            <Col span={5}>
                                {record.status}
                            </Col>
                            <Col span={5} style={{ textAlign: 'center' }}>
                                {record.events.length} Events
                            </Col>
                            <Col span={4} style={{ display: 'flex', gap: 15, justifyContent: 'flex-end' }}>
                                <img src={EditSvg} alt="delete" onClick={() => { getWebhookDetail(record); }} />
                                <img src={DeleteSvg} alt="delete" onClick={() => onDelete(record)} />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </div>}
        {webhooks && webhooks.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: '80vh', flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Webhook</div>
            <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first Webhook.</div>
            <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Webhook yet...</div>
            <div style={{ display: 'flex' }}>
                <button className="sp_btn" style={{ width: "auto" }} onClick={() => { setOpenDrawer(true) }} >+ Add Webhook</button>
            </div>
        </div>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        <Save webhook={webhook} isVisible={openDrawer} onClose={onDrawerClose}></Save>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div >
}

export default Webhook;