import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Drawer, Input, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import UploadSvg from '../../../../../../../assets/images/upload.svg';
import JSONPng from '../../../../../../../assets/images/json-icon.png';
import { isNotNullOrUndefinedString, uuidv4 } from "../../../../../../../helpers/util";
import ColorPalette from "../../../../../common/ColorPalette";
import InputValidation from "../../../../../common/InputValidation";
import { MediaAssets } from "../../../../../common/mediaAssets";
import config from '../../../../../../../apiConfig';
import { useDispatch } from "react-redux";
import { onUploadMedia } from "../../../../../../../actions/commonAction";

const UploadHolder = styled.div`
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;   
    background: #fff;     
    .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 79px;
        height: 76px;
        background: #FFFFFF;
        border: 1px solid #9AA3AD;
        border-radius: 4px;
        label {
            font-size:  12px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #848484;
            margin-bottom: 15px;
        }
    }
`;
const ErrorInfo = {
    appName: {
        message: "",
        type: ""
    },
    bundleId: {
        message: "",
        type: ""
    },
    appIcon: {
        message: "",
        type: ""
    },
    googleServicesConfig: {
        message: "",
        type: ""
    }
}
// const { Option } = Select;
const Save = (props: any) => {
    const dispatch = useDispatch();
    const { appConfig, onSave }: any = props;
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [error, setError] = useState(ErrorInfo);
    const [appName, setAppName] = useState(null);
    const [splashColor, setSplashColor] = useState('#00BDFF');
    const [appIcon, setAppIcon] = useState([]);
    const [googleServicesConfig, setGoogleServicesConfig] = useState(null);
    const [bundleId, setBundleId] = useState(null);
    // const [images, setImages] = useState([]);
    useEffect(() => {
        onSetBundleId();
        //eslint-disable-next-line
    }, [selectedStore]);
    const onSetBundleId = () => {
        if (selectedStore && selectedStore.name) {
            setBundleId(`com.myshopletzy.${selectedStore.name}`)
        }
    }
    const onUpdate = async () => {
        let errorInfo = { ...ErrorInfo };
        let isError = false;
        if (!isNotNullOrUndefinedString(appName)) {
            errorInfo.appName.message = "Enter the app name";
            errorInfo.appName.type = "error";
            isError = true;
        } else {
            errorInfo.appName.message = "";
            errorInfo.appName.type = "";
        }
        if (!googleServicesConfig || googleServicesConfig.length === 0) {
            errorInfo.googleServicesConfig.message = "Upload the google services config";
            errorInfo.googleServicesConfig.type = "error";
            isError = true;
        } else {
            errorInfo.googleServicesConfig.message = "";
            errorInfo.googleServicesConfig.type = "";
        }
        if (!isNotNullOrUndefinedString(bundleId)) {
            errorInfo.bundleId.message = "Enter the bundle id";
            errorInfo.bundleId.type = "error";
            isError = true;
        } else {
            errorInfo.bundleId.message = "";
            errorInfo.bundleId.type = "";
        }
        if (!appIcon || (appIcon && appIcon.length === 0)) {
            errorInfo.appIcon.message = "Please choose app icon";
            errorInfo.appIcon.type = "error";
            isError = true;
        } else {
            errorInfo.appIcon.message = "";
            errorInfo.appIcon.type = "";
        }
        setError(errorInfo);
        if (!isError) {
            const resp: boolean = await onSave({
                appName,
                appIcon: appIcon[0].url.replace(config.baseImageUrl, ''),
                splashColor,
                platform: 'android',
                appType: 'shop-android',
                bundleId,
                googleServicesConfig
            });
            if (resp === true) {
                onClose();
            }
        }
    }
    const onClose = () => {
        setError(ErrorInfo);
        setAppName(null);
        setSplashColor("#00BDFF");
        setGoogleServicesConfig(null);
        setAppIcon([]);
        onSetBundleId();
        props.onClose();
    }
    const handleChangeGSC = ({ fileList }) => {
        if (fileList && fileList.length > 1) {
            fileList.shift();
        }
        if (fileList && fileList.length > 0) {
            setGoogleServicesConfig(fileList[0]);
        }
    }
    const onUploadFiles = (fileList, disableImageTransform) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            if (!disableImageTransform) {
                formData.append('imageTransform', 'w:500');
            }
            const resp = await onUploadMedia(formData)(dispatch);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    if (resp.error) {
                        console.error("Error uploading files");
                    } else {
                        urls = resp.map((r) => {
                            return {
                                id: uuidv4(),
                                url: r.files && r.files.length > 0 && r.files[0]
                            }
                        });
                        debugger
                        setAppIcon(urls);
                    }
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }
    useEffect(() => {
        if (appConfig?.appName) {
            setAppName(appConfig.appName);
        }
        if (appConfig?.splashColor) {
            setSplashColor(appConfig.splashColor);
        }
        if (appConfig?.appIcon) {
            const url = appConfig.appIcon.indexOf('https://') > -1 ? appConfig.appIcon : `${config.baseImageUrl}${appConfig.appIcon}`
            setAppIcon([{
                id: uuidv4(),
                url
            }]);
        }
        if (appConfig?.bundleId) {
            setBundleId(appConfig.bundleId);
        }
        if (appConfig?.googleServicesConfig) {
            setGoogleServicesConfig(appConfig.googleServicesConfig);
        }
        //Eslint-disable-next-line
    }, [appConfig]);
    return <Drawer
        maskClosable={false}
        width={470}
        closable={false}
        onClose={onClose}
        className="drawer-view"
        open={props.isVisible}
        footer={
            <div className='footer'>
                <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                <div className="sp_btn" onClick={onUpdate} style={{ width: 164, fontSize: 14 }}>Submit Request</div>
            </div>
        }>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
            <div className="title">App Information</div>
        </div>
        <Row className="content" >
            <Col span={24}>
                <Row className="group" style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <div className="label" style={{ marginBottom: 5 }} >App Name *</div>
                    </Col>
                    <Col span={24}>
                        <InputValidation type={error.appName.type} message={error.appName.message}>
                            <Input className="value" onChange={(e) => setAppName(e.target.value)} value={appName} />
                        </InputValidation>
                    </Col>
                </Row>
                <Row className="group" style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <div className="label" style={{ marginBottom: 5 }} >Bundle Id *</div>
                    </Col>
                    <Col span={24}>
                        <InputValidation type={error.bundleId.type} message={error.bundleId.message}>
                            <Input className="value" onChange={(e) => setBundleId(e.target.value)} value={bundleId} />
                        </InputValidation>
                    </Col>
                </Row>
                <Row className="group" style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <div className="label" style={{ marginBottom: 5 }} >Splash Screen Color</div>
                    </Col>
                    <Col span={24}>
                        <ColorPalette defaultColor={splashColor} onColorChange={(val) => { setSplashColor(val) }} />
                    </Col>
                </Row>
                <Row className="group" style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <div className="label" style={{ marginBottom: 5 }} >App Icon</div>
                    </Col>
                    <Col span={24}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <UploadHolder>
                                <MediaAssets isEdit={true} multiple={false}
                                    key="app-icon"
                                    defaultValue={appIcon}
                                    onSort={(items) => {
                                        let urls = items.map((item) => {
                                            return {
                                                id: uuidv4(),
                                                url: item.url
                                            }
                                        });
                                        setAppIcon([...urls]);
                                    }}
                                    uploadIcon={UploadSvg}
                                    onSubmit={async ({ fileList, selectedPreIcon, disableImageTransform }) => {
                                        let urls = [];
                                        if (selectedPreIcon && selectedPreIcon.length > 0) {
                                            urls = selectedPreIcon.map((item) => {
                                                return {
                                                    url: item.url,
                                                    id: uuidv4()
                                                }
                                            });
                                        }
                                        if (fileList && fileList.length > 0) {
                                            onUploadFiles(fileList, disableImageTransform)
                                        } else {
                                            setAppIcon(urls);
                                        }
                                    }}
                                    onRemove={(item) => {
                                        let newImages = appIcon.filter((img) => img.url !== item.url);
                                        setAppIcon(newImages);
                                    }} />
                                <label style={{ color: "#888", fontWeight: 500, fontSize: 12 }}>App icon size 1080 X 1080</label>
                            </UploadHolder>
                            {error.appIcon.message && <div style={{ color: "#fff", background: "#FF0000" }}>{error.appIcon.message}</div>}
                        </div>
                    </Col>
                </Row>
                <Row className="group" style={{ marginTop: 30 }}>
                    <Col span={24}>
                        <div className="label" style={{ marginBottom: 5 }} >Google Services Config *</div>
                    </Col>
                    <Col span={24}>
                        <InputValidation type={error.googleServicesConfig.type} message={error.googleServicesConfig.message}>
                            <UploadHolder>
                                <Upload
                                    customRequest={() => { return false; }}
                                    multiple={false}
                                    showUploadList={false}
                                    accept=".json"
                                    onChange={handleChangeGSC}
                                >
                                    <div className="icon">
                                        {googleServicesConfig ? <img src={JSONPng} alt="json icon" style={{ width: 50 }} /> : <img alt="Upload" src={UploadSvg} />}
                                    </div>
                                </Upload>
                                <label style={{ color: "#888", fontWeight: 500, fontSize: 12 }}>Upload google service config.json file</label>
                            </UploadHolder>
                        </InputValidation>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Drawer>
}

export default Save;