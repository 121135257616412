import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { useOutletContext } from "react-router-dom";
import relativeTime from 'dayjs/plugin/relativeTime';
import config from '../../../../../../../apiConfig';
import EmptyIcon from "../../../../../../../assets/images/settings/app_generation.svg";
dayjs.extend(relativeTime);

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    width: auto;
`;
const HeaderTitle = styled.div`
    color: #121212;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
`;
const HeaderSubTitle = styled.div`
    color: #5F6369;
    font-size: 15px;
    font-weight: 400;
`;
const Title = styled.div`
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 13px;
`;
const SubTitle = styled.div`
    color: #707070;
    font-size: 13px;
    width: 600px;
    font-weight: 500;
`;
const Row = styled.div`
    display: flex;
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
`;
const Value = styled.div`
    color: #121212;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const AppIcon = styled.div`
    width: 90px;
    height: 90px;
    border-radius: 10px;
    border: 1px solid #121212;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Empty = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 104px;
        height: 129px;
        margin-bottom: 45px;
    }
    .title {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 13px;
    }
    .desc {
        font-size: 14px;
        letter-spacing: 0px;
        color: #848484;
        margin-bottom: 17px;
    }
`;
const BuildNow = styled.div`
    height: 44px;
    background: #121212;
    border-radius: 4px;
    color: #FFF;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    cursor: pointer;
`;
const AppConfig = (props: any) => {
    const { appConfig, onNewAppSettings }: any = useOutletContext();
    return appConfig ? <Container style={{ margin: 15 }}>
        <Col>
            <Row style={{ marginBottom: 19 }}>
                <Col>
                    <HeaderTitle>App Configuration</HeaderTitle>
                    <HeaderSubTitle>Setting Key Parameters: App Name, Bundle ID, Firebase Integration, Icon, and Splash Screen Customization</HeaderSubTitle>
                </Col>
            </Row>
            <Row style={{ marginBottom: 17 }}>
                <Col>
                    <Title>App Name</Title>
                    <Row>
                        <SubTitle>Crafting a Unique Identity: Selecting the Perfect Name for Your Application</SubTitle>
                        <Value>{appConfig?.appName}</Value>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: 17 }}>
                <Col>
                    <Title>Bundle ID</Title>
                    <Row>
                        <SubTitle>Establishing Your App's Unique Identifier: Navigating Bundle IDs</SubTitle>
                        <Value style={{ textTransform: "unset" }}>{appConfig?.bundleId}</Value>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: 17 }}>
                <Col>
                    <Title>Splash Screen Color</Title>
                    <Row>
                        <SubTitle>Choosing the Right Color for Your App's Splash Screen</SubTitle>
                        <Value>
                            <div style={{ background: appConfig?.splashColor, width: 18, height: 18, borderRadius: 6, marginRight: 6 }}></div>
                            {appConfig?.splashColor}
                        </Value>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: 17 }}>
                <Col>
                    <Title>App Icon</Title>
                    <Row>
                        <SubTitle>Designing a Memorable App Icon: Perfecting a 512x512 Visual Masterpiece"</SubTitle>
                        <AppIcon>
                            <img style={{ width: "inherit", height: "inherit", padding: 5, borderRadius: 10 }}
                                src={`${appConfig?.appIcon.indexOf('https://') > -1 ? appConfig?.appIcon : config.baseImageUrl + appConfig?.appIcon}`}
                                alt="icon" />
                        </AppIcon>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: 17 }}>
                <Col>
                    <Title>Google Service Configurations</Title>
                    <Row>
                        <SubTitle>Optimizing Android Connectivity: Setting Up Google Service Configurations</SubTitle>
                        <Value>
                            {appConfig?.googleServicesConfig ? "UPLOADED" : "Not UPLOADED"}
                        </Value>
                    </Row>
                </Col>
            </Row>

        </Col>
    </Container> :
        <Empty style={{ height: window.innerHeight - 260 }}>
            <img alt="Empty" src={EmptyIcon} />
            <div className="title">Configure Your Android App</div>
            <div className="desc">
                Tailor your app's name, bundleId, app icon, and splash color to match your brand's identity.
            </div>
            <BuildNow onClick={onNewAppSettings}>Let's Configure</BuildNow>
        </Empty>
}

export default AppConfig;