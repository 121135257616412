import { useState } from "react";


export default function EventsSelector({ eventGroups, onToggleGroupEvents, onToggleEvent, selectedEvents, setSelectedEvents }) {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredGroups = Object.entries(eventGroups).reduce((acc, [group, events]: any) => {
    const filteredEvents = events?.filter(({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase()));
    if (filteredEvents.length > 0) {
      acc[group] = filteredEvents;
    }
    return acc;
  }, {});
  return (
    <div className="events-container">
      <div className="events-header">
        <h2>Events</h2>
        <button className="clear-button" onClick={() => setSelectedEvents({})}>
          Clear All
        </button>
      </div>
      <div className="events">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <button className="clear-search" onClick={() => setSearchTerm("")}>✕</button>
          )}
        </div>
        <div className="event-list">
          {Object.entries(filteredGroups).map(([group, events]: any) => (
            <div key={group} className="event-group">
              <div className="group-label">
                <div
                  className="checkbox"
                  onClick={() => onToggleGroupEvents(group)}
                >
                  {events.every(({ event }) => selectedEvents[event]) && <div className={`selected`} />}
                </div>
                {group}
              </div>
              {events.map(({ event, label }) => (
                <div key={event} className="event-label">
                  <div
                    className={`checkbox`}
                    onClick={() => onToggleEvent(event)}
                  >
                    {selectedEvents[event] && <div className={`selected`} />}
                  </div>
                  {label}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}