import React, { useEffect, useState } from "react";
import Add from "./add";
import "../../../../../assets/sass/dsk/sitemap.sass";
import SearchIcn from "../../../../../assets/images/search.svg"
import { getSitemaps, removeSitemap } from "../../../../../actions/sitemapActions";
import DeleteSvg from "../../../../../assets/images/settings/trash.svg";
import { useDispatch } from "react-redux";
import Loading from '../../../common/Loading';
import { Input, Row, Col } from "antd";
import PopupConfirm from '../../../common/PopupConfirm';

const Sitemap = () => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [sitemaps, setSitemaps] = useState(null);
    const [filterSitemaps, setFilterSitemaps] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const onLoad = async () => {
        setIsLoading(true);
        const resp = await getSitemaps()(dispatch);
        if (resp?.sitemapUrls) {
            setSitemaps(resp.sitemapUrls)
        } else {
            setSitemaps([]);
        }
        setIsLoading(false);
    }
    const onDrawerClose = (isLoad) => {
        setOpenDrawer(false);
        if (isLoad) {
            onLoad();
        }
    }
    const onFilter = () => {
        let filterResults = [];
        let isAdd = false;
        for (let s of sitemaps) {
            if (!search || (search.trim().length === 0)) {
                filterResults.push(s);
            } else {
                if (s.url.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    isAdd = true;
                }
                if (s.changeFrequency.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    isAdd = true;
                }
            }
            if (isAdd) {
                filterResults.push(s);
            }
        }
        setFilterSitemaps(filterResults);
    }
    useEffect(() => {
        if (sitemaps) {
            onFilter();
        }
        //eslint-disable-next-line
    }, [search]);
    useEffect(() => {
        if (sitemaps) {
            onFilter();
        }
        //eslint-disable-next-line
    }, [sitemaps])

    const onDelete = (item) => {
        console.log(item)
        const title = `Sitemap remove url`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold", textTransform: "uppercase", marginRight: 4 }}>{item.url}</span>url</span></div > },
            yes: {
                label: "Remove",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await removeSitemap(item.id)(dispatch);
                    if (resp && resp.isDeleted) {
                        onLoad();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    useEffect(() => {
        onLoad();
        //eslint-disable-next-line
    }, []);
    return <div className="sitemap">
        {sitemaps !== null && sitemaps.length > 0 && <div className="action-bar">
            <Input className="filter" name="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" suffix={<img alt='Search Icon' src={SearchIcn} />} />
            <button className="sp_btn" style={{ width: "auto" }} onClick={() => { setOpenDrawer(true) }} >+ Add Url</button>
        </div>}
        {sitemaps && sitemaps.length > 0 && <div className="sm-container">
            <Row className="header" style={{ marginLeft: 30 }}>
                <Col span={10}>
                    Product Name
                </Col>
                <Col span={5}>
                    Change frequency
                </Col>
                <Col span={5} style={{ textAlign: 'center' }}>
                    Priority
                </Col>
                <Col span={4}>
                </Col>
            </Row>
            <Row className="list">
                <Col span={24}>
                    {filterSitemaps && filterSitemaps.map((record, index) =>
                        <Row className="item" key={record.id}>
                            <Col span={10}>
                                {record.url}
                            </Col>
                            <Col span={5}>
                                {record.changeFrequency}
                            </Col>
                            <Col span={5} style={{ textAlign: 'center' }}>
                                {record.priority}
                            </Col>
                            <Col span={4} style={{ textAlign: 'right' }}>
                                <img src={DeleteSvg} alt="delete" onClick={() => onDelete(record)} />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </div>}
        {sitemaps && sitemaps.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: '80vh', flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Sitemap</div>
            <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first sitemap.</div>
            <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Sitemap yet...</div>
            <div style={{ display: 'flex' }}>
                <button className="sp_btn" style={{ width: "auto" }} onClick={() => { setOpenDrawer(true) }} >+ Add Url</button>
            </div>
        </div>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        <Add isVisible={openDrawer} onClose={onDrawerClose}></Add>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default Sitemap;