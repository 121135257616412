import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
// import { EllipsisOutlined } from "@ant-design/icons";
import { getProducts, getProductById, productsExport, bulkImport } from '../../../actions/productActions';
import { getOutlets } from '../../../actions/outletAction';
import { onProductCatalogFilterOptions } from '../../../actions/uiAction';
import { getL10N } from "../../../helpers/middleware";
import { Row, Col, Input, Badge } from 'antd';
import config from '../../../apiConfig';
import '../../../assets/sass/dsk/product.sass';
import SearchIcn from "../../../assets/images/search.svg"
// import viewIcn from '../../../assets/images/view.svg';
// import ImageNotLoaded from "../../../assets/images/not-found.png";
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
// import SortUpSvg from "../../../assets/images/sort_up.svg";
// import SortDownSvg from "../../../assets/images/sort_down.svg";
// import deleteIcn from '../../../assets/images/delete.png';
// import editIcn from '../../../assets/images/edit.png';
import Save from './Save';
import dayjs from 'dayjs';
import PopupConfirm from '../common/PopupConfirm';
import Loading from '../common/Loading';
import BulkUpload from '../../common/BulkUpload';
import VideoSvg from "../../../assets/images/media-icons/video.svg";
import AudioSvg from "../../../assets/images/media-icons/audio.svg";
import Unknown from "../../../assets/images/media-icons/unknown.svg";
import { uuidv4 } from '../../../helpers/util';

// const menus = [{
//     icon: editIcn,
//     key: "edit",
//     value: "Edit"
// }, {
//     icon: deleteIcn,
//     key: "remove",
//     value: "Remove"
// }];

const ProductCatalog = (props: any) => {

    const outlets = useSelector((state: any) => state.outlet.outlets);
    // const selectedOutletList = useSelector((state: any) => state.common.selectedOutlets);
    const { selectedStore, productCatalogFilterOptions } = useSelector((state: any) => state.ui);
    // const defaultSelectedLang = getLocale();
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let windowHeight = window.innerHeight;
    // const [selectedLang, setSelectedLang] = useState(defaultSelectedLang);
    // const [selectedTabMenu, setSelectedTabMenu] = useState(null);
    // const [productList, setProductList] = useState(null);
    const [products, setProducts] = useState([]);
    const [isBulkUpload, setBulkUpload] = useState(false);
    const fields = [{
        label: "Title",
        key: "title",
        alternateMatches: ["title"],
        fieldType: {
            type: "input",
        },
        example: "Title",
        validations: [
            {
                rule: "required",
                errorMessage: "Title is required",
                level: "error",
            },
        ],
    }, {
        label: "Sub Title",
        key: "subtitle",
        alternateMatches: ["subtitle"],
        fieldType: {
            type: "input",
        },
        example: "sub title",
        validations: [
            {
                rule: "required",
                errorMessage: "Sub Title is required",
                level: "error",
            },
        ],
    }, {
        label: "Description",
        key: "description",
        alternateMatches: ["description"],
        fieldType: {
            type: "input",
        },
        example: "Description",
        validations: [
            {
                rule: "required",
                errorMessage: "description is required",
                level: "error",
            },
        ],
    }, {
        label: "SKU",
        key: "sku",
        alternateMatches: ["sku"],
        fieldType: {
            type: "input",
        },
        example: "7aa2",
        validations: [
            {
                rule: "required",
                errorMessage: "SKU is required",
                level: "error",
            },
        ],
    }, {
        label: "Slug",
        key: "slug",
        alternateMatches: ["slug"],
        fieldType: {
            type: "input",
        },
        example: "slug",
        validations: [
            {
                rule: "required",
                errorMessage: "Slug is required",
                level: "error",
            },
        ],
    }, {
        label: "Labels",
        key: "labels",
        alternateMatches: ["labels"],
        fieldType: {
            type: "select",
            options: [
                { value: "topPick", label: "Top Pick" },
                { value: "whatsNew", label: "Whats New" },
                { value: "bestSeller", label: "Best Seller" },
            ],
        },
        example: "labels"
    }, {
        label: "Seo Title",
        key: "seoTitle",
        alternateMatches: ["seoTitle"],
        fieldType: {
            type: "input",
        },
        example: "seo title"
    }, {
        label: "Seo Description",
        key: "seoDescription",
        alternateMatches: ["seoDescription"],
        fieldType: {
            type: "input",
        },
        example: "Seo description"
    }, {
        label: "Tax Rate",
        key: "taxRate",
        alternateMatches: ["taxRate"],
        fieldType: {
            type: "input",
        },
        example: 0
    }, {
        label: "HSN Code",
        key: "hsnCode",
        alternateMatches: ["hsnCode"],
        fieldType: {
            type: "input",
        },
        example: "123"
    }];
    // const [selectMenu, setSelectMenu] = useState("");
    // const [selectedOutlet, setSelectedOutlet] = useState("all");
    // const [productSearch, setProductSearch] = useState("");
    // const [categorySearch, setCategorySearch] = useState("");
    // const [priceSearch, setPriceSearch] = useState("");
    const [search, setSearch] = useState("");
    const [action, setAction] = useState(null);
    const [filterProducts, setFilterProducts] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    // const [defaultVariantDetails, setDefaultVariantDetails] = useState({
    //     options: null,
    //     variants: null
    // });
    // const [selectedProducts, setSelectedProducts] = useState({
    //     ids: []
    // });

    // const [skuSearch, setSKUSearch] = useState(null);
    // const [outletCountSearch, setOutletCountSearch] = useState("");
    // const [sort, setSort] = useState("up");

    // useEffect(() => {
    //     loadProducts();
    // }, [])

    // useEffect(() => {
    //     if (locale) {
    //         setSelectedLang(locale)
    //     }
    // }, [locale])

    useEffect(() => {
        loadProducts();
        //eslint-disable-next-line
    }, [selectedStore]);

    const loadProducts = async () => {
        setIsLoading(true);
        let respProducts = await props.getProducts();
        await props.getOutlets();
        setIsLoading(false);
        if (respProducts) {
            setProducts(respProducts);
        }
    }

    useEffect(() => {
        const { query } = productCatalogFilterOptions;
        if (query !== search) {
            setSearch(query);
        }
        onFilterProducts();
        //eslint-disable-next-line
    }, [outlets, productCatalogFilterOptions, products]);

    useEffect(() => {
        const handler = setTimeout(() => {
            props.onProductCatalogFilterOptions({ query: search });
        }, 500);
        return () => {
            clearTimeout(handler);
        };
        //eslint-disable-next-line
    }, [search]);

    const normalizeVOptions = (options, vOptions) => {
        let normalizedVOptions = {};
        options.forEach(option => {
            const { name, values } = option;
            const matchingKey = Object.keys(vOptions).find(
                key => key.toLowerCase() === name.toLowerCase()
            );
            if (matchingKey) {
                const vValue = vOptions[matchingKey];
                const matchingValue = values.find(
                    value => value.toLowerCase() === vValue.toLowerCase()
                );
                if (matchingValue) {
                    normalizedVOptions[name] = matchingValue; // Use case-sensitive name and value
                }
            }
        });

        return normalizedVOptions;
    }

    const onAction = async (p, a) => {
        if (p && p.id) {
            const resp = await props.getProductById(p.id);
            let variantOptions = [];
            if (resp) {
                let formattedOptions = [];
                if (resp?.options && resp?.options.length > 0) {
                    formattedOptions = resp.options.map(attribute => ({
                        id: uuidv4(),
                        name: attribute.name,
                        values: attribute.values.map(value => ({
                            id: uuidv4(),
                            name: value
                        }))
                    }));
                    if (resp?.variants) {
                        variantOptions = resp.variants.filter((v) => {
                            if (v.options) {
                                v.options = normalizeVOptions(resp.options, v.options)
                            }
                            return v
                        });
                    }
                }
                setSelectedProduct({
                    ...resp,
                    ...{
                        'defaultVariantDetails': {
                            options: formattedOptions,
                            variants: variantOptions
                        }
                    }
                });
            }
        } else if (a === "export") {
            setIsLoading(true);
            const blob = await props.productsExport();
            setIsLoading(false);
            if (blob) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `product_catalog_${new Date().toLocaleDateString()}.xlsx`;
                document.body.append(link);
                link.click();
                link.remove();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            }
            return;
        } else {
            const defaultVariantDetails = {
                options: [],
                variants: []
            }
            setSelectedProduct(defaultVariantDetails);
        }
        setAction(a);
        setIsVisible(true);
    }

    const loadCategories = (record) => {
        let categories = [];
        if (record.categories && record.categories.length > 0) {
            for (let i in record.categories) {
                let searchRegExp = /\//g;
                let replaceWith = ' / ';
                categories.push(<Badge key={record.categories[i]} count={record.categories[i].replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase())} />);
            }
        }
        return categories;
    }

    // const onSelectOutlet = (values) => {
    //     setSelectedOutlet(values);
    // }

    // const loadStockDetails = (record) => {
    //     let details = {
    //         key: null,
    //         val: null
    //     }
    //     if (loadOutletBasedProduct(record, "isAvailable")) {
    //         details["key"] = "instock";
    //         details["val"] = "In Stock";
    //     }
    //     if (loadOutletBasedProduct(record, "outOfStock")) {
    //         details["key"] = "outstock";
    //         details["val"] = "Out of Stock";
    //     }
    //     return details && details.key && <div className={details.key}>{details.val}</div>;
    // }

    // const onHandleDeleteYesProduct = (product) => {
    //     console.log(`Delete Product ${product}`)
    // }

    // const onSelectedProduct = (product) => {
    //     if (selectedProducts.ids.indexOf(product.id) > -1) {
    //         selectedProducts.ids.splice(selectedProducts.ids.indexOf(product.id), 1);
    //     } else {
    //         selectedProducts.ids.push(product.id);
    //     }
    //     setSelectedProducts(selectedProducts);
    // }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     let updateProps: any = {};
    //     if (nextProps.ui.locale !== prevState.selectedLang) {
    //         updateProps.selectedLang = nextProps.ui.locale;
    //     }
    //     if (nextProps.tast && nextProps.common.selectedOutlets !== prevState.selectedLang) {
    //         updateProps.selectedOutlets = nextProps.common.selectedOutlets;
    //     }
    //     if (nextProps.ui.selectedTabMenu !== prevState.selectedTabMenu) {
    //         updateProps.selectedTabMenu = nextProps.ui.selectedTabMenu;
    //     }
    //     return updateProps;
    // }

    // const onChangeFilter = (evt) => {
    //     const value = evt.target.value;
    //     this.setState({
    //         [evt.target.name]: !value ? "" : value
    //     }, () => this.onFilterProducts());
    // }

    const onFilterProducts = () => {
        // let { products, categorySearch, productSearch, skuSearch, sort } = this.state;
        let filterResults = [];
        // let isProduct = false;
        // let isCategory = false;
        let isSearch = false;
        // let isOutlet = false;
        // let isSKU = false;
        for (let p of products) {
            // isProduct = false;
            // isOutlet = false;
            // isCategory = false;
            // isStock = false;
            isSearch = false;
            if (search) {
                let title = getL10N(p, "title", null).i10n;
                if ((title && title.toLowerCase().indexOf(search.toLowerCase()) > -1) || p.sku?.includes(search)) {
                    isSearch = true
                }
            }
            if (search) {
                let categoryNames = p.categories.join('|').replace("/", "");
                if (categoryNames.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    isSearch = true
                }
            }
            if (search) {
                if (p?.sku && p.sku?.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    isSearch = true
                }
            }
            // if (selectedOutlet && selectedOutlet !== "all") {
            //     for (let i in p.outlets) {
            //         if (p.outlets[i].ouId === selectedOutlet) {
            //             isOutlet = true;
            //         }
            //     }
            // } else {
            //     isOutlet = true;
            // }
            if ((!search || (search.trim().length === 0) || isSearch)) {
                filterResults.push(p);
            }
        }

        // filterResults.sort(function (a: any, b: any) {
        //     if (sort === "down") {
        //         return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        //     } else {
        //         return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        //     }
        // });
        setFilterProducts(filterResults)
    }

    // const renderMenu = (data) => {
    //     const content = [];
    //     for (let i in menus) {
    //         content.push(
    //             <div key={menus[i].key}>
    //                 <div style={{ display: "flex", color: selectMenu == menus[i].key ? "#37a2fd" : "#000000", fontWeight: selectMenu == menus[i].key ? 800 : 100 }}>
    //                     <div>
    //                         <img src={menus[i].icon} style={{
    //                             padding: "7px",
    //                             width: "22px"
    //                         }} />
    //                     </div>
    //                     <div>
    //                         <p onClick={() => onMenuChange(menus[i], data)}>{menus[i].value}</p>
    //                     </div>
    //                 </div>
    //                 <div style={{ textAlign: "center", padding: "0px 8px" }}>
    //                     <div style={{ height: "1px", background: "#CAD1DB", width: "100%" }}></div>
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return content;
    // }
    // const onMenuChange = (selectMenu, data) => {
    //     console.log(selectMenu, data)
    //     onAction(data, selectMenu.key)
    // }
    // const renderOutletName = (ouId) => {
    //     let getOutletInfo = outlets.filter((item) => (item.id === ouId));
    //     // let outletName = "Not Available";
    //     return getOutletInfo.length > 0 && getOutletInfo[0].name;
    // }
    const onBulkUpload = (data) => {
        const { validData } = data;
        if (validData && validData.length > 0) {
            const payload = validData.map((item) => {
                if (item.taxRate) {
                    item.taxRate = parseInt(item.taxRate);
                } else {
                    item.taxRate = 0;
                }
                if (item.hsnCode) {
                    item.hsnCode = parseInt(item);
                }
                if (item.labels) {
                    item.labels = [item.labels];
                }
                return item;
            });
            let popupConfirmDetail = null;
            popupConfirmDetail = {
                title: "Bulk Upload!",
                renderContent: () => {
                    return <div>
                        <div>Are you sure? Do you want upload the products?</div>
                    </div>
                },
                yes: {
                    label: "Yes",
                    callback: async () => {
                        setIsLoading(true);
                        const resp = await props.bulkImport(payload);
                        if (resp && !resp.error) {
                            loadProducts();
                        }
                        setPopupConfirm(null);
                        setIsLoading(false);
                    }
                },
                no: {
                    label: "No",
                    callback: () => { setPopupConfirm(null) }
                }
            }
            setPopupConfirm(popupConfirmDetail);
        }
    }

    function getMediaType(input) {
        if (typeof input === 'string') {
            if (input.startsWith('data:')) {
                const mime = input.split(';')[0].split(':')[1];
                switch (mime) {
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/webp':
                    case 'image/svg':
                    case 'image/ico':
                    case 'image/gif':
                        return 'image';
                    case 'audio/mpeg':
                    case 'audio/mp3':
                    case 'audio/wav':
                    case 'audio/ogg':
                    case 'audio/midi':
                        return 'audio';
                    case 'video/mpeg':
                    case 'video/mp4':
                    case 'video/mov':
                    case 'video/webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            } else {
                const extension = input.split('.').pop().toLowerCase();
                switch (extension) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'webp':
                    case 'svg':
                    case 'ico':
                    case 'gif':
                        return 'image';
                    case 'mp3':
                    case 'wav':
                    case 'ogg':
                    case 'midi':
                        return 'audio';
                    case 'mp4':
                    case 'mov':
                    case 'mpeg':
                    case 'webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            }
        } else {
            return 'unknown';
        }
    }

    return (
        <Row className="product">
            <Col className="list" style={{ height: windowHeight - 76 }}>
                <div className="action-bar" style={{ paddingLeft: 24 }}>
                    <div style={{ width: 396 }} >
                        <Input className="filter" name="search" value={search} onChange={(event) => {
                            setSearch(event.target.value)
                        }} placeholder="Search" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                    </div>
                    <div className="actions">
                        <button className="sp_btn btn" onClick={() => onAction(null, "new")} >+ Add Product</button>
                        <button className="sp_btn btn" onClick={() => setBulkUpload(true)} >Bulk Upload</button>
                        <button className="sp_btn btn" onClick={() => onAction(null, "export")} >Export</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header" style={{ marginLeft: 30 }}>
                        <Col span={11}>
                            Product Name
                        </Col>
                        <Col span={11}>
                            Category
                        </Col>
                        {/* <Col span={5}>
                            Outlets
                        </Col>
                        <Col span={3}>
                            SKU
                        </Col> */}
                        <Col span={2} style={{ textAlign: 'right', paddingRight: 10 }}>
                            Updated At
                        </Col>
                    </Row>
                    {/* <Row className="filters">
                        <Col span={7}>
                            <Input className="filter" name="productSearch" onChange={(event) => setProductSearch(event.target.value)} placeholder="Product Name" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={6}>
                            <Input className="filter" placeholder="Category" name="categorySearch" onChange={(event) => setCategorySearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={5}>
                            <Select
                                className="select"
                                size="middle"
                                defaultValue={selectedOutlet}
                                onChange={onSelectOutlet}
                            >
                                <Option key={"all"} style={{ paddingLeft: '16px' }} value={"all"}>
                                    ALL
                                </Option>
                                {outlets && outlets.map((item) => {
                                    return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                        {item.name}
                                    </Option>
                                })}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <Input className="filter" placeholder="SKU" value={skuSearch} name="skuSearch" onChange={(event) => setSKUSearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={3}>
                            <div style={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                                <div style={{
                                    background: sort === "up" ? '#d6e6f2' : "#FFF",
                                    border: sort === "up" ? '1px solid #d6e6f2' : "1px solid #cacaca",
                                    marginRight: 20,
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                    height: 28,
                                    width: 28,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} onClick={() => setSort("up")}><img alt='SortUp' src={SortUpSvg} width={20} /></div>
                                <div style={{
                                    background: sort === "down" ? '#d6e6f2' : "#FFF",
                                    border: sort === "down" ? '1px solid #d6e6f2' : "1px solid #cacaca",
                                    marginRight: 20,
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                    height: 28,
                                    width: 28,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} onClick={() => setSort("down")}><img alt='Sort Down' src={SortDownSvg} width={20} /></div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row className="contains">
                        <Col span={24}>
                            {filterProducts && filterProducts.map((record, index) =>

                                <Row className="item" onClick={() => onAction(record, "view")} key={record.id}
                                >
                                    <Col span={11}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                {(!record.images || record.images.length === 0) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                                                    marginRight: "10px",
                                                    width: "50px",
                                                    borderRadius: "3px"
                                                }} />}
                                                {record.images && record.images.length > 0 && (
                                                    <div style={{ display: 'flex' }}>
                                                        {record.images && record.images.length > 0 && (
                                                            <div style={{ display: 'flex' }}>
                                                                {/* Render placeholder images based on media type of the first image */}
                                                                {getMediaType(record.images && record.images[0]) === 'video' && (
                                                                    <img src={VideoSvg} alt="Video_Placeholder" className="placeholder-icon" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.images && record.images[0]) === 'audio' && (
                                                                    <img src={AudioSvg} alt="Audio_Placeholder" className="placeholder-icon" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.images && record.images[0]) === 'unknown' && (
                                                                    <img src={Unknown} alt="Unknown_Placeholder" className="placeholder-icon" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {/* Render actual first image */}
                                                                {getMediaType(record.images && record.images[0]) === 'image' && (<img
                                                                    alt='Product Icon'
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }}
                                                                    src={`${config.baseImageUrl}${record.images[0]}`}
                                                                />)}
                                                            </div>
                                                        )}

                                                    </div>
                                                )}

                                            </div>
                                            <div className='row-ellipsis'>
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        marginBottom: 4
                                                    }}>{getL10N(record, "title", null).i10n}</div>
                                                <div style={{
                                                    color: "#606060",
                                                    fontSize: 12
                                                }}>{getL10N(record, "subtitle", null).i10n}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={11}>
                                        <div className="category">{loadCategories(record)}</div>
                                    </Col>
                                    {/* <Col span={5}>
                                        <div className='row-ellipsis' style={{ display: "flex", marginLeft: 4 }}>{record.outlets && record.outlets.map((item) => {
                                            const name = item && renderOutletName(item.ouId);
                                            return <div key={item.ouId} title={name} style={{
                                                width: 26,
                                                height: 26,
                                                background: '#000',
                                                color: '#d6e6f2',
                                                borderRadius: 4,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 10,
                                            }}>{name && name.charAt(0)}</div>
                                        })}</div>
                                    </Col>
                                    <Col span={3} style={{ textAlign: "left" }}>
                                        <div className='row-ellipsis' style={{ marginLeft: 4 }}>{record.sku}</div>
                                    </Col> */}
                                    <Col span={2} style={{ textAlign: 'right' }}>
                                        <div className='row-ellipsis'>{dayjs(record.updatedAt).calendar(null, {
                                            sameDay: "[Today] h:mm A",
                                            nextDay: "[Tomorrow] h:mm A",
                                            nextWeek: "ddd h:mm A",
                                            lastDay: "[Yesterday] h:mm A",
                                            lastWeek: "[Last] dddd h:mm A",
                                            sameElse: "MMM D YYYY [at] h:mm A",
                                        })}</div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="footer">
                    You've got {products && products.length} products listed
                </div>
            </Col>
            <BulkUpload isOpen={isBulkUpload} maxRecords={2000} onClose={() => setBulkUpload(false)} onSubmit={onBulkUpload} fields={fields} />
            <Save onClose={() => {
                setIsVisible(false);
                setSelectedProduct(null);
                setAction(null);
                loadProducts();
            }} action={action} selectedProduct={selectedProduct} isVisible={isVisible} />
            {isLoading && <Loading background="#00000070"></Loading>}
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        </Row >
    )
}

function mapStateToProps(state: any) {
    return {
        outlet: state.outlet,
        product: state.product,
        auth: state.auth,
        common: state.common,
        ui: state.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProducts,
        getProductById,
        getOutlets,
        onProductCatalogFilterOptions,
        bulkImport,
        productsExport
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalog);
