import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getListedProductVariants, removeProductFromOutlet } from '../../../actions/productActions';
import { selectedMainMenu, onListedProductFilterOptions } from '../../../actions/uiAction';
import { getL10N } from "../../../helpers/middleware";
import { Row, Col, Select, Input } from 'antd';
import config from '../../../apiConfig';
import '../../../assets/sass/dsk/product.sass';
import SearchIcn from "../../../assets/images/search.svg";
// import deleteIcn from '../../../assets/images/delete.png';
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
import ProductToOutlet from './ProductToOutlet';
// import PopupConfirm from '../common/PopupConfirm';
import { useNav } from '../../../helpers/customHook';
import Loading from '../common/Loading';
import VideoSvg from "../../../assets/images/media-icons/video.svg";
import AudioSvg from "../../../assets/images/media-icons/audio.svg";
import Unknown from "../../../assets/images/media-icons/unknown.svg";
import VisibilityOnSvg from "../../../assets/images/visibility-on.svg";
import VisibilityOffSvg from "../../../assets/images/visibility-off.svg";
import { getOutlets } from '../../../actions/outletAction';

const { Option } = Select;

const ListedProducts = (props: any) => {
    const navigateTo = useNav();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const outlets = useSelector((state: any) => state.outlet.outlets);
    const { selectedStore, listedProductFilterOptions, selectedOuId } = useSelector((state: any) => state.ui);
    let windowHeight = window.innerHeight;
    const [productVariantList, setProductVariantList] = useState(null);
    const [productVariants, setProductVariants] = useState([]);
    const [selectedOutletId, setSelectedOutletId] = useState(null);
    const [search, setSearch] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    // const [popupConfirm, setPopupConfirm] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedStore) {
                await getOutlets()(dispatch);
            }
        }
        fetchData();
        //eslint-disable-next-line
    }, [selectedStore]);

    useEffect(() => {
        // if (productVariantList == null) {
        onFilterProducts();
        // }
        //eslint-disable-next-line
    }, [productVariantList]);

    useEffect(() => {
        if (selectedOutletId) {
            // onFilterProducts();
            loadProducts();
        }
        //eslint-disable-next-line
    }, [selectedOutletId]);

    useEffect(() => {
        const { query } = listedProductFilterOptions;
        if (outlets && outlets.length > 0) {
            if (query !== search) {
                setSearch(query);
            }
            if (selectedOuId && selectedOuId !== 'all') {
                setSelectedOutletId(selectedOuId);
            } else {
                setSelectedOutletId(outlets[0].id);
            }
            onFilterProducts();
        }
        //eslint-disable-next-line
    }, [outlets, listedProductFilterOptions, selectedOuId]);

    useEffect(() => {
        const handler = setTimeout(() => {
            props.onListedProductFilterOptions({ ouId: selectedOutletId, query: search });
        }, 500);
        return () => {
            clearTimeout(handler);
        };
        //eslint-disable-next-line
    }, [search, productVariantList])

    const loadProducts = async () => {
        setLoading(true);
        let resp = await props.getListedProductVariants(selectedOutletId);
        setLoading(false);
        setProductVariantList(resp);
    }

    const onAction = (product, action) => {
        setSelectedProduct(product);
        if (action === "view" || action === "new") {
            setIsVisible(true);
        }
    }

    // const isStockStatus = (record) => {
    //     let status = true;
    //     if (!loadOutletBasedProduct(record, "isAvailable", [selectedOutletId])) {
    //         status = false;
    //     }
    //     if (loadOutletBasedProduct(record, "outOfStock", [selectedOutletId])) {
    //         status = false;
    //     }
    //     return status;
    // }

    const onFilterProducts = () => {
        if (productVariantList) {
            let filterProducts = [];
            let isSearch = false;
            for (let p of productVariantList) {
                isSearch = false;
                if (search) {
                    let title = getL10N(p, "title", null).i10n;
                    if ((title && title.toLowerCase().indexOf(search.toLowerCase()) > -1) || p.sku?.toLowerCase().indexOf(search.toLowerCase()) > -1 || (p.subtitle && p.subtitle.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                        isSearch = true
                    }
                    if (parseFloat(p.salePrice) === parseFloat(search) || parseFloat(p.price) === parseFloat(search)) {
                        isSearch = true
                    }
                }
                if (!search || search.trim().length === 0 || isSearch) {
                    filterProducts.push(p);
                }
            }
            setProductVariants(filterProducts);
        }
    }

    // const removeProduct = (p) => {
    //     const selectedOutletDetail = outlets.filter((o) => o.id === selectedOutletId);
    //     const title = `Product remove from outlet`;
    //     setPopupConfirm({
    //         title,
    //         renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{p.title}</span> from {selectedOutletDetail[0].name} outlet.</span></div > },
    //         yes: {
    //             label: "Remove Product",
    //             callback: async () => {
    //                 const resp = await props.removeProductFromOutlet(p.productId, selectedOutletId);
    //                 if (resp && resp.isUpdated) {
    //                     loadProducts();
    //                 }
    //                 setPopupConfirm(null);
    //             }
    //         },
    //         no: {
    //             label: "May be next time",
    //             callback: () => { setPopupConfirm(null); }
    //         }
    //     });
    // }

    function getMediaType(input) {
        if (typeof input === 'string') {
            if (input.startsWith('data:')) {
                const mime = input.split(';')[0].split(':')[1];
                switch (mime) {
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/webp':
                    case 'image/svg':
                    case 'image/ico':
                    case 'image/gif':
                        return 'image';
                    case 'audio/mpeg':
                    case 'audio/mp3':
                    case 'audio/wav':
                    case 'audio/ogg':
                    case 'audio/midi':
                        return 'audio';
                    case 'video/mpeg':
                    case 'video/mp4':
                    case 'video/mov':
                    case 'video/webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            } else {
                const extension = input.split('.').pop().toLowerCase();
                switch (extension) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'webp':
                    case 'svg':
                    case 'ico':
                    case 'gif':
                        return 'image';
                    case 'mp3':
                    case 'wav':
                    case 'ogg':
                    case 'midi':
                        return 'audio';
                    case 'mp4':
                    case 'mov':
                    case 'mpeg':
                    case 'webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            }
        } else {
            return 'unknown';
        }
    }

    const onGenerateVariant = (options) => {
        let title = "";
        for (let op of Object.keys(options)) {
            title += ` ${title.length > 0 ? " / " : ""} ${options[op]}`;
        }
        return title;
    }

    return (
        <Row className="product">
            {/* <Col span={24}>
                    <div className="head">Products</div>
                </Col> */}
            {isLoading && <Loading background="#00000070"></Loading>}
            {(outlets && outlets.length > 0) && <Col className="list" style={{ height: windowHeight - 76 }}>
                <div className="action-bar" style={{ paddingLeft: 24 }}>
                    {/* <div className="title">
                        You've got {productVariants && productVariants.length} productVariants listed
                    </div> */}
                    <div style={{ width: 396 }} >
                        <Input className="filter" name="search" value={search} onChange={(event) => {
                            setSearch(event.target.value)
                        }} placeholder="Search" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                    </div>
                    <div className="actions">
                        <Select
                            className="select"
                            // maxTagCount={2}
                            // mode="tags"
                            size="middle"
                            value={selectedOutletId}
                            defaultValue={selectedOutletId}
                            onChange={(value) => { setSelectedOutletId(value) }}
                        // style={{ maxHeight: "60px", overflowY: "auto", borderColor: 'rgb(199 200 214)', borderRadius: "1px", width: '100%' }}
                        >
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                    <label htmlFor={item.id} style={{ paddingLeft: "16px" }}>{item.name}</label>
                                </Option>
                            })}
                        </Select>
                        <button style={{ width: 300 }} className="sp_btn btn" onClick={() => onAction(null, "new")} >+ Add Product To Outlet</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header" style={{ marginLeft: 30 }}>
                        <Col span={8}>
                            Product Name
                        </Col>
                        <Col span={7}>
                            SKU
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            Price
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            Sale Price
                        </Col>
                        <Col span={3} style={{ textAlign: 'center' }}>
                            On Hand Stock
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            Visibility
                        </Col>
                        {/* <Col style={{ textAlign: "center" }} span={2}>
                            Action
                        </Col> */}
                    </Row>
                    <Row className="contains" >
                        <Col span={24}>
                            {productVariants && productVariants.map((record, index) =>
                                <Row className="item" style={{ height: 'auto' }} onClick={() => onAction(record, "view")} key={record.variantId}
                                // style={{ border: this.state.productVariants.length - 1 <= index ? "border: 1px solid #E7EEF7" : "1px solid #E7EEF7" }}
                                >
                                    <Col span={8}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                {/* <span className={loadOutletBasedProduct(record, "isAvailable") ? "active" : "inactive"}></span> */}
                                                {(!record.media || record.media.length === 0) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                                                    marginRight: "10px",
                                                    width: "50px",
                                                    borderRadius: "3px"
                                                }} />}

                                                {record.media && record.media.length > 0 && (
                                                    <div style={{ display: 'flex' }}>
                                                        {record.media && record.media.length > 0 && (
                                                            <div style={{ display: 'flex' }}>
                                                                {/* Render placeholder media based on media type of the first image */}
                                                                {getMediaType(record.media && record.media[0]) === 'video' && (
                                                                    <img src={VideoSvg} alt="Video_Placeholder" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.media && record.media[0]) === 'audio' && (
                                                                    <img src={AudioSvg} alt="Audio_Placeholder" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.media && record.media[0]) === 'unknown' && (
                                                                    <img src={Unknown} alt="Unknown_Placeholder" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {/* Render actual first image */}
                                                                {getMediaType(record.media && record.media[0]) === 'image' && (<img
                                                                    alt='Product Icon'
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }}
                                                                    src={`${config.baseImageUrl}${record.media[0]}`}
                                                                />)}
                                                            </div>
                                                        )}

                                                    </div>
                                                )}
                                            </div>
                                            <div className='row-ellipsis'>
                                                <div
                                                    className='col-ellipsis'
                                                    style={{
                                                        fontSize: 14,
                                                        marginBottom: 10
                                                    }}>{getL10N(record, "title", null).i10n}</div>
                                                <div style={{
                                                    color: "#606060",
                                                    fontSize: 12,
                                                    marginBottom: 10
                                                }}>{getL10N(record, "subtitle", null).i10n}</div>
                                                <div style={{
                                                    color: "#606060",
                                                    fontSize: 12
                                                }}>{record.options && onGenerateVariant(record.options)}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={7}>
                                        <div className="category" style={{ wordWrap: 'break-word' }}>{record.sku}</div>
                                    </Col>
                                    <Col span={2} >
                                        <div style={{ textAlign: 'right', paddingRight: 2 }}>₹{record.price ?? 0}</div>
                                    </Col>
                                    <Col span={2} >
                                        <div style={{ textAlign: 'right', paddingRight: 2 }}>₹{record.salePrice ?? 0}</div>
                                    </Col>
                                    <Col span={3} style={{ textAlign: "center" }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                color: (record.available === 0 || record.available <= 4) ? '#C70039' : '#121212',
                                                fontWeight: (record.available === 0) ? 500 : 'normal'
                                            }}>
                                            {(record.available === 0) ? 'Out of Stock' : record.available}
                                        </div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>
                                        <img src={record.isVisible ? VisibilityOnSvg : VisibilityOffSvg} alt="visible" />
                                    </Col>
                                    {/* <Col span={2} >
                                        <div style={{ textAlign: 'center' }}><img alt='Delete Icon' onClick={(e) => { e.stopPropagation(); removeProduct(record) }} style={{ cursor: 'pointer' }} src={deleteIcn} /></div>
                                    </Col> */}
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="footer">
                    You've got {productVariants && productVariants.length} product variants listed
                </div>
            </Col>}
            {(!outlets || outlets.length === 0) && <Col span={24}> <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column' }}>
                <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Add Products to Outlet</div>
                {/* <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first FAQ.</div> */}
                <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Outlet created yet...</div>
                <div style={{ display: 'flex' }}>
                    <button onClick={() => { props.selectedMainMenu('outlets'); navigateTo(`/${selectedStore.name}/settings/store/outlet`); }} className="sp_btn" style={{ width: 140 }} >+ Create Outlet</button>
                </div>
            </div></Col>}
            {/* {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>} */}
            <ProductToOutlet onClose={() => { setIsVisible(false); loadProducts(); }} selectedOutletId={selectedOutletId} defaultValue={selectedProduct} isVisible={isVisible} />
        </Row >
    )
    // }
    // }

}

// const mapStateToProps = (state: any) => {
//     // console.log("Product mapStateToProps: ", state);
//     return {
//         outlet: state.outlet,
//         product: state.product,
//         auth: state.auth,
//         ui: state.ui
//     }
// }

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getListedProductVariants,
        removeProductFromOutlet,
        selectedMainMenu,
        onListedProductFilterOptions
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ListedProducts)
