import React, { useEffect, useState } from "react";
import Save from "./save";
import "../../../../../assets/sass/dsk/businessHours.sass";
import { getStoreDetails } from "../../../../../actions/brandAction";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import CustomHoursSvg from "../../../../../assets/images/custom-hours.svg";
import Loading from "../../../common/Loading";

const Days = {
    "mon": "monday",
    "tue": "tuesday",
    "wed": "wednesday",
    "thu": "thursday",
    "fri": "friday",
    "sat": "saturday",
    "sun": "sunday",
};
const BusinessHours = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [businessHours, setBusinessHours] = useState(null);
    const [editBusinessHours, setEditBusinessHours] = useState(null);
    const [sameTimeOpen, setSameTimeOpen] = useState("09:30");
    const [sameTimeClose, setSameTimeClose] = useState("18:30");
    const [timingType, setTimingType] = useState(null);
    const [customTimingHours, setCustomTimingHours] = useState({
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
    });
    const [customTiming24Hours, setCustomTiming24Hours] = useState({
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
    });
    const [hourType, setHourType] = useState("24 Hours");
    const loadStoreDetails = async () => {
        setLoading(true);
        const resp: any = await getStoreDetails()(dispatch);
        if (resp && resp.businessHours) {
            setBusinessHours(resp.businessHours);
        } else {
            setBusinessHours({});
        }
        setLoading(false);
    }
    useEffect(() => {
        if (!isVisible) {
            loadStoreDetails();
        }
        //eslint-disable-next-line
    }, [isVisible])
    useEffect(() => {
        let timing24Hours = {
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false
        }
        let timingHours = {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: []
        }
        if (businessHours) {
            let hType = "24 Hours";
            let tType = "Same Timing"
            if (businessHours.closed) {
                hType = "Closed";
                tType = "All days"
            } else if (businessHours.days) {
                let allDaysTiming = [];
                for (let d in businessHours.days) {
                    if (businessHours.days[d].open24hours) {
                        timing24Hours[d] = true
                        timingHours[d] = [{ status: "24 Hours" }];
                        tType = "Custom Timing";
                        setCustomTiming24Hours({ ...customTiming24Hours, [d]: true })
                    } else {
                        hType = "Custom Hours"
                        if (businessHours.days[d].hours) {
                            let isSameTiming = { open: null, close: null };
                            let hTime = [];
                            for (let h in businessHours.days[d].hours) {
                                if (isSameTiming.open === null) {
                                    isSameTiming.open = businessHours.days[d].hours[h].openTime
                                }
                                if (isSameTiming.close === null) {
                                    isSameTiming.close = businessHours.days[d].hours[h].closeTime
                                }
                                if (allDaysTiming.indexOf(`${businessHours.days[d].hours[h].openTime}-${businessHours.days[d].hours[h].closeTime}`) === -1) {
                                    allDaysTiming.push(`${businessHours.days[d].hours[h].openTime}-${businessHours.days[d].hours[h].closeTime}`)
                                }
                                if (isSameTiming.open !== businessHours.days[d].hours[h].openTime || isSameTiming.close !== businessHours.days[d].hours[h].closeTime) {
                                    tType = "Custom Timing"
                                }
                                hTime.push({
                                    open: businessHours.days[d].hours[h].openTime.slice(0, 2) + ":" + businessHours.days[d].hours[h].openTime.slice(2, 4),
                                    close: businessHours.days[d].hours[h].closeTime.slice(0, 2) + ":" + businessHours.days[d].hours[h].closeTime.slice(2, 4),
                                })
                            }
                            timingHours[d] = hTime;
                            // setCustomTimingHours({ ...customTimingHours, [d]: hTime });
                            if (tType === "Same Timing") {
                                setSameTimeOpen(isSameTiming.open.slice(0, 2) + ":" + isSameTiming.open.slice(2, 4));
                                setSameTimeClose(isSameTiming.close.slice(0, 2) + ":" + isSameTiming.close.slice(2, 4));
                            }
                        } else if (businessHours.days[d].closed) {
                            tType = "Custom Timing"
                            timingHours[d] = [{ status: "Closed" }];
                        }
                    }
                }
                if (allDaysTiming.length > 1) {
                    tType = "Custom Timing"
                }
                if (hType === "24 Hours") {
                    setCustomTiming24Hours({ ...customTiming24Hours, ...timing24Hours })
                } else if (hType === "Custom Hours") {
                    setCustomTimingHours({ ...customTimingHours, ...timingHours });
                }
            }
            setHourType(hType)
            setTimingType(tType);
        }
        //eslint-disable-next-line
    }, [businessHours])

    const onClose = () => {
        setVisible(false);
        setEditBusinessHours(null);
    }

    const renderCustomTiming = (timing) => {
        let times = [];
        let count = 1;
        for (let t of timing) {
            if (t.status) {
                times.push(<div key={`${count}_${t.status}`} style={{ color: t.status.toLocaleLowerCase() === "closed" ? "#FF0000" : t.status.toLocaleLowerCase() === "24 hours" ? "#02BA65" : "#1C3A66" }}>{t.status}</div>)
            } else if (t.open && t.close) {
                times.push(<div key={`${count}_${t.status}`} style={{ display: "flex", color: '#1C3A66', fontWeight: 'normal', width: 160, justifyContent: 'space-between', height: 40, alignItems: 'center' }}><div>{dayjs(t.open, "HH:mm").format('hh:mm a')}</div> - <div>{dayjs(t.close, "HH:mm").format('hh:mm a')}</div></div>)
            }
            count++;
        }
        return times;
    }

    return <div className="business_hours">
        {isLoading && <Loading background="#00000070"></Loading>}
        {businessHours && typeof businessHours === 'object' && Object.keys(businessHours).length > 0 && <div className="header">
            <div style={{ flexGrow: 1 }}>
                <div className="title">Business hours for your stores</div>
                <div className="subtitle">Configure the standard hours of operation for your store.</div>
            </div>
            <div className="sp_btn" style={{ width: 92 }} onClick={() => { setEditBusinessHours(businessHours); setVisible(true) }}>
                Edit
            </div>
        </div>}
        {businessHours && typeof businessHours === 'object' && Object.keys(businessHours).length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: window.innerHeight - 200, flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Add Business Hours</div>
            {/* <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first FAQ.</div> */}
            <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Business Hours created yet...</div>
            <div style={{ display: 'flex' }}>
                <button onClick={() => { setEditBusinessHours(businessHours); setVisible(true) }} className="sp_btn" style={{ width: 140 }} >+ Create</button>
            </div>
        </div>}
        {businessHours && typeof businessHours === 'object' && Object.keys(businessHours).length > 0 && <div className="contents">
            <div className="section">
                <div style={{ fontSize: 16, display: 'flex' }}>
                    <div style={{ color: '#4E4E4E', width: '20%' }}>
                        <div style={{ fontWeight: 900, marginBottom: 29 }}>Days</div>
                        {hourType !== "Closed" && <div style={{ fontWeight: 900 }}>Hours</div>}
                    </div>
                    <div>
                        <div style={{ marginBottom: 29 }}>
                            {/* {hourType === "Closed" ? businessHours.closedMessage : Object.keys(customTimingHours).map((item, i) => {
                                return <div key={i} style={{ textTransform: 'capitalize' }}>
                                    {customTimingHours[item].length > 0 && Days[item]}
                                </div>
                            })} */}
                            {hourType === "Closed" && <div>{timingType} closed</div>}
                            {hourType === "Custom Hours" && timingType === "Custom Timing" && <div style={{ fontWeight: 400, fontSize: 16 }}>Custom Days</div>}
                            {(hourType === "24 Hours" || timingType === "Same Timing") && <div style={{ fontWeight: 400, fontSize: 16 }}>Sunday - Monday - Tuesday - Wednesday - Thursday - Friday - Saturday</div>}
                        </div>
                        {/* <div>{hourType}</div> */}
                        {hourType === "Closed" && <div style={{ fontWeight: 900 }}>{businessHours.closedMessage}</div>}
                        {hourType === "Custom Hours" && timingType === "Same Timing" && <div style={{ display: 'flex' }}>
                            <div style={{ fontWeight: 900, marginRight: 10 }}>Open at</div> {dayjs(sameTimeOpen, "hh mm").format('hh:mm A')} - <div style={{ fontWeight: 900, margin: '0px 10px' }}>Close at</div> {dayjs(sameTimeClose, "hh mm").format('hh:mm A')}
                        </div>}
                        {hourType === "24 Hours" && <div style={{ fontWeight: 900, marginRight: 10 }}>24 Hours</div>}
                        {hourType === "Custom Hours" && timingType === "Custom Timing" && <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', color: '#188FFC', fontSize: 16 }}>
                            <img alt="Custom Hours" src={CustomHoursSvg} style={{ marginRight: 4 }} />
                            <div>Custom Hours</div>
                        </div>}
                        {hourType === "Custom Hours" && timingType === "Custom Timing" && <div style={{ display: 'flex', flexDirection: 'column', width: 400 }}>
                            {Object.keys(customTimingHours).map((item, i) => {
                                return <div key={`${i}_custom_timing_hours`} style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, padding: "20px 0px", alignItems: 'center', borderBottom: `${item.toLocaleLowerCase() === "sun" ? 0 : 1}px solid #D8DEE6` }}>
                                    {/* {customTimingHours[item].length > 0 && Days[item]} */}
                                    <div style={{ textTransform: 'capitalize', color: '#1C3A66' }}>{Days[item.toLocaleLowerCase()]}</div>
                                    <div style={{ fontWeight: 900 }}>{renderCustomTiming(customTimingHours[item])}</div>
                                </div>
                            })}
                        </div>
                        }
                    </div>
                </div>
                {businessHours && businessHours.closedMessage && <div style={{ marginTop: 20, fontSize: 16, display: 'flex' }}>
                    <div style={{ color: '#4E4E4E', width: '20%' }}>
                        {<div style={{ fontWeight: 900 }}>Closed Message</div>}
                    </div>
                    <div>
                        {businessHours.closedMessage}
                    </div>
                </div>}
            </div>
        </div>}
        <Save businessHours={editBusinessHours} isVisible={isVisible} onClose={onClose}></Save>
    </div>
}

export default BusinessHours;